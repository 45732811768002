/*美团匹配弹窗 */ 
<template>
  <div>
    <el-dialog
      title="匹配酒店"
      :visible.sync="dialogFormVisible"
      width="35%"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <!-- <el-form-item label="城市" :label-width="formLabelWidth">
          <el-input
            v-model="form.city"
            autocomplete="off"
            placeholder="请输入城市名称"
            size="small"
          ></el-input>
        </el-form-item>
       

        <el-form-item label="酒店地址" :label-width="formLabelWidth">
          <el-input
            v-model="form.hotelAddress"
            autocomplete="off"
            placeholder="请酒店地址"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="酒店电话" :label-width="formLabelWidth">
          <el-input
            v-model="form.hotelTel"
            autocomplete="off"
            placeholder="请输入酒店联系方式"
            size="small"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="酒店ID" :label-width="formLabelWidth">
          <el-input
            v-model="hotelId"
            autocomplete="off"
            placeholder="请输入酒店ID"
            size="small"
          ></el-input>
        </el-form-item>
         <el-form-item label="酒店名称" :label-width="formLabelWidth">
          <el-input
            v-model="form.hotelName"
            autocomplete="off"
            placeholder="请输入酒店名称"
            size="small"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <div class="btn">
            <el-button size="small" @click="close">取 消</el-button>
            <el-button size="small" type="primary" @click="Onsubmit"
              >匹 配</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { manualMatching_api, getMatchingMtHotel } from "../../../apis/hotel";
export default {
  props: {
    getMatchList: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        hotelTel: "",
        hotelAddress: "",
        hotelName: "",
        city: "",
      },
      formLabelWidth: "100px",
      list: [],
      hotelId:''
    };
  },
  methods: {
    open(row) {
      // console.log(row)
      this.list = row;
      this.dialogFormVisible = true;
    },
    // 取消
    close() {
      this.dialogFormVisible = false;
    },
    // 匹配
    async Onsubmit() {
      let { code, data } = await getMatchingMtHotel({
        hotelSonVestId: this.list.id,
        hotelId:this.hotelId,
        hotelName:this.form.hotelName
      });
      if (code == 0) {
        console.log(data);
        this.$message({
          message: "匹配成功",
          type: "success",
        });
        this.getMatchList();
        this.dialogFormVisible = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog {
  border-radius: 20px;
}
.el-form {
  width: 70%;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: space-around;
  padding-top: 10px;
  box-sizing: border-box;
}
</style>