<template>
  <!-- 待下线酒店 -->
  <div class="offlineHotel">
    <div class="nav">
      <div class="btn">
        <el-button
          size="mini"
          type="primary"
          plain
          :class="btnCur == index ? 'active' : ''"
          v-for="(item, index) in btn"
          :key="index"
          @click="btnFn(index)"
          >{{ item.name }}</el-button
        >
      </div>
      <!-- 酒店查询 -->
      <el-form
        v-if="
          role.includes('all') ||
          role.includes('hr') ||
          role.includes('caiwu_jl') ||
          role.includes('market_jl') ||
          role.includes('operate_jl')||
           role.includes('market_baofang_zg')
        "
        :inline="true"
        label-width="auto"
        :model="formInline"
        class="demo-form-inlines"
      >
        <el-form-item label="职位">
            <el-select
              size="mini"
              style="width: 120px"
              v-model="formInline.roleName"
              @change="getGroups"
              placeholder="请选择"
            >
              <el-option
                v-for="item in jobOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="组别">
            <el-select
              size="mini"
              style="width: 120px"
              @change="getPersons"
              v-model="formInline.group"
              placeholder="请选择"
            >
              <el-option
                v-for="(item, index) in groupOptions"
                :key="item.keyy"
                :label="item.valuee"
                :value="index"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="人员">
            <el-select
              size="mini"
              style="width: 120px"
              v-model="formInline.people"
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in personOptions"
                :key="item.keyy"
                :label="item.valuee"
                :value="item.valuee"
              ></el-option>
            </el-select>
          </el-form-item>
        <el-form-item>
          <el-button size="mini" type="success" @click="query">查询</el-button>
          <el-button size="mini" type="primary" plain  @click="batchMoveFn"
            >批量移动</el-button
          >
          <el-button
            size="mini"
            v-if="btnCur == '1'"
            type="primary"
            plain
            @click="samaDownline"
            >批量下线</el-button
          >
          <el-button v-if="role.includes('market_jl')" size="mini" type="primary" plain @click="samaPass"
            >批量通过</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- 酒店列表 -->
    <div class="list">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        max-height="500"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" align="center"> </el-table-column>
        <el-table-column
          prop="cityName"
          label="城市/酒店名称 备注-星级"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="status" align="center" label="售卖状态">
          <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.statusList" :key="index">
              <div
                :class="
                  item.value == '1' ? 'changeColor' : 'changeColor active'
                "
              >
                {{ item.key }}:{{ item.value == "1" ? "在售" : "停售" }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="orderNum"
          align="center"
          label="订单量"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="nightNum" align="center" label="间夜量">
        </el-table-column>
        <el-table-column
          prop="preOverthrow"
          align="center"
          label="确认前推翻率"
        >
        </el-table-column>
        <el-table-column prop="achievement" align="center" label="业绩">
        </el-table-column>
        <el-table-column prop="productManager" align="center" label="产品经理">
        </el-table-column>
        <el-table-column prop="onlineTime" align="center" label="上线时间">
        </el-table-column>
        <el-table-column prop="retentionDays" align="center" label="保留天数">
        </el-table-column>
        <el-table-column
        
          prop="applyReason"
          align="center"
          label="申请原因"
        >
        </el-table-column>
        <el-table-column
          v-if="false"
          prop="applyAuditStatus"
          align="center"
          label="申请状态"
        >
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="330" align="center">
          <template slot-scope="scope">
            <div class="tabBox">
              <div
              @click="apply(scope.row)"
              v-if="btnCur == '0'"
              class="operation"
            >
              申请
            </div>
            <div
              v-if="btnCur == '1'"
              class="operation"
              @click="downLine(scope.row)"
            >
              下线
            </div>
            <div
              v-if="btnCur == '1' && role.includes('market_jl')"
              class="operation"
              @click="reject(scope.row)"
            >
              驳回
            </div>
            <div
              v-if="btnCur == '1' && role.includes('market_jl')"
              class="operation"
              @click="pass(scope.row.hotelId)"
            >
              通过
            </div>
            <div v-if="false" class="operation" @click="resubmit">重新提交</div>
            <div class="operation" @click="journal(scope.row)">日志</div>

            </div>
            
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        style="float: right"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="count"
        :page-sizes="[15, 30, 45, 60]"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 申请下线弹出框 -->
    <OfflineApply :getOffLine="getOffLine" ref="offlineApply"></OfflineApply>
    <DownLine :getOffLine="getOffLine" ref="downline" />
    <SameDownline :getOffLine="getOffLine" ref="samedownline" />
    <SameApp :getOffLine="getOffLine" ref="sameapp" />
    <PassDiaglog :getOffLine="getOffLine" ref="pass" />
    <RejectDiaglog :getOffLine="getOffLine" ref="reject" />
     <BatchMoveDialog
      :getCoreLyHotelListFn="getOffLine"
      ref="batchMoveDialog"
    ></BatchMoveDialog>
  </div>
</template>

<script>
import { getGroups_api } from "../../../apis/workbench";
import { getLine_api, getGroupList_api } from "../../../apis/hotel";
import OfflineApply from "./OfflineApply.vue";
import DownLine from "./DownLine";
import SameDownline from "./SameDownline.vue";
import SameApp from "./SameApp.vue";
import PassDiaglog from "./PassDiaglog.vue";
import RejectDiaglog from "./RejectDiaglog.vue";
import BatchMoveDialog from "./BatchDialog.vue";
//引入local
import local from "@/utils/local.js";
export default {
  components: {
    OfflineApply,
    DownLine,
    SameDownline,
    SameApp,
    PassDiaglog,
    RejectDiaglog,
     BatchMoveDialog,
  },
    props:['parentsData'],
  data() {
    return {
      //酒店查询表单
      formInline: {
        roleName: "",
        group: "",
        people: "",
         goupId: "",
      
      },
      ajaxVestId: "", // 接口请求vest的id
      ajaxGroupId: "", // 接口请求group的id
      ajaxPersonId: "", // 接口请求person的id
      groupOptions: [], // 组别选择
      personOptions: [], // 人员选择
      jobOptions: [
        { label: "全部", value: "" },
        { label: "产品", value: 0 },
        { label: "运营", value: 1 },
      
      ], // 职位选择
      groupList: [],
      currentPage: 1, //当前页
      count: 15, //当前页条数
      total: 0, //总数
      tableData: [], //酒店表格
      //选项按钮
      btn: [{ name: "未申请" },{ name: "已申请" } ],
      // 选项卡按钮
      btnCur: "0",
      //角色
      role: JSON.stringify(local.get("role")),
      multipleSelection: [],
       searchData: {
        //type: "1",
        isSearch: "1",
        page: "",
        rows: "",
        roleName: "",
        goupId: "",
        people: "",
      },
    };
  },
  mounted() {
    this.getOffLine();
  },
  methods: {
      getChooseDataFn(){
       let getChooseData={
         hotelSonIds:this.hotelSonIds,
         roleName:this.formInline.roleName,
         goupId:this.groupOptions[this.formInline.group]?this.groupOptions[this.formInline.group].keyy:"",
         people:this.formInline.people
       }
       return getChooseData
    },
    //分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.count = val;
      this.getOffLine();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getOffLine();
    },
    //多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
       console.log(this.multipleSelection);
    },
      // 批量移动弹窗
    batchMoveFn() {
      if (this.multipleSelection.length >= 1) {
        this.$refs.batchMoveDialog.open(this.multipleSelection);
      } else {
        this.$message({
          type: "info",
          message: "请您至少选择一家酒店哦!",
        });
      }
    },
      // 获取组别
    async getGroups(val) {
      let { code, data } = await getGroups_api({
        positionType: val,
      });
      if (code == 0) {
        this.formInline.group = "";
        this.formInline.people = "";
          this.formInline.goupId = "";
        this.personOptions = [];
        this.groupOptions = data; // 后续可能更改格式
      }
    },
    // 获取组的信息(获取对应的人员列表)
    getPersons(val) {
      this.formInline.people = ""; // 人员为空
      this.personOptions = this.groupOptions[val].list;
      this.ajaxGroupId = this.groupOptions[val].keyy; // 组id
       this.formInline.goupId = this.groupOptions[val].keyy; // 组id
      // console.log(this.ajaxGroupId, "组id");
    },
   
    // 清空
    clear() {
      this.formInline.roleName = "";
      this.formInline.goupId = "";
      this.formInline.people = "";

      this.searchData.roleName = '';
      this.searchData.goupId = '';
      this.searchData.people = '';
      this.groupOptions = [];
      this.personOptions = [];
    },
    //获取待下线酒店
    async getOffLine(obj) {
       let searchDataa=JSON.parse(JSON.stringify(this.searchData))
      let searchDataf=Object.assign(searchDataa,this.formInline,this.parentsData)
      // this.$parent.formInline
      searchDataf.page = this.currentPage;
      searchDataf.rows = this.count;
      searchDataf.applyStatus = this.btnCur;

      let { code, data } = await getLine_api(
        searchDataf
      //   {
      //   page: this.currentPage,
      //   rows: this.count,
      //   isSearch: "1",
      //  roleName: this.formInline.roleName,
      //   goupId: this.ajaxGroupId == undefined ? "" : this.ajaxGroupId,
      //   people: this.formInline.people,
      //   applyStatus: this.btnCur,
      //   cityName: obj ? obj.city : "",
      //   provinceName: obj ? obj.province : "",
      //   ctripHotelName: obj ? obj.name : "",
      //   start: obj ? obj.star : "",
      //   typeCore: obj ? obj.tag : "",
      //   Ctrip: obj ? obj.vest : "",
      //   CtripStatus: obj ? obj.xiecheng : "",
      //   QunarStatus: obj ? obj.qunaer : "",
      //   MeiTuanStatus: obj ? obj.meituan : "",
      //   FilPigStatus: obj ? obj.feizhu : "",
      //   SameCityStatus: obj ? obj.yiling : "",
      //    timeStart: obj ? obj.timeStart : "",
      //   timeEnd: obj ? obj.timeEnd : "",
      // }
      );
      if (code == 0) {
      //  console.log(data, "68788");
        this.tableData = data.records;
        this.total = data.total;
      }
    },
    // 选项卡点击事件
    btnFn(index) {
      console.log(index);
      this.btnCur = index;
      this.getOffLine();
      // if (index == 0) {
      //   this.getOffLine();
      // } else {
      //   this.getOffLine();
      // }
    },
    //申请下线酒店
    apply(row) {
      this.$refs.offlineApply.open(row);
    },
    // 选择职位
    changeJob(data) {
      // console.log(data);
      this.formInline.job = data;
      this.formInline.group = "";
      this.getGroupList();
    },
    // 获取组别
    async getGroupList() {
      let { code, data } = await getGroupList_api({
        positionType: this.formInline.job == "产品" ? 0 : 1,
      });
      if (code == 0) {
        // console.log(data, "111111");
        this.groupList = data;
      }
    },
    query() {
       this.currentPage=1
      this.getOffLine();
    },
    // 驳回
    reject(row) {
      this.$refs.reject.open(row);
    },
    // 通过
    pass(hotelId) {
      this.$refs.pass.open(hotelId);
    },
    samaPass(){
       if (this.multipleSelection.length >= 1) {
         let hotelIds=[]
         this.multipleSelection.forEach((item)=>{
           hotelIds.push(item.hotelId)
         })
        this.$refs.pass.open(hotelIds.join(','));
      } else {
        this.$message({
          type: "info",
          message: "请您至少选择一家酒店哦!",
        });
      }
    },
    // 重新提交
    resubmit() {},
    // 日志
    journal(row) {
      this.$router.push({
        path: "/roomloglist",
        query: {
          id: row.hotelId,
          hotelName: row.cityName,
        },
      });
    },
    // 下线
    downLine(row) {
      this.$refs.downline.open(row);
    },
    // 批量下线
    samaDownline() {
      if (this.multipleSelection.length >= 0) {
        this.$refs.samedownline.open(this.multipleSelection);
      } else {
        this.$message({
          type: "info",
          message: "至少选择一个酒店",
        });
      }
    },
    // 批量申请
    samaApp() {
      if (this.multipleSelection.length >= 1) {
        this.$refs.sameapp.open(this.multipleSelection);
      } else {
        this.$message({
          type: "info",
          message: "至少选择一个酒店",
        });
      }
    },
    getMultipleSelection(){
      return this.multipleSelection
    }
  },
};
</script>

<style lang="less" scoped>
.nav {
  background: #fff;
  padding: 15px 10px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  .btn {
    .el-button.active {
      background: #256def;
      color: #fff;
    }
  }
  .demo-form-inlines {
    height: 40px;
  }
}
.operation {
  color: #256ef1;
  cursor: pointer;
}
.changeColor{
  font-size: 12px;
  color: #33B93F;
  &.active{
    color: #EE4D52;
  }
}
.tabBox {
  width: 200px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 60px;
  div {
    width: 70px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #b3d8ff;
    background: #ecf5ff;
    margin: 0px 4px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
  }
}
</style>