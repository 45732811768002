<template>
  <!-- 通过审核 -->
  <div class="auditPass">
    <el-dialog
      title="通过审核"
      :visible.sync="dialogFormVisible"
      width="35%"
      top="10rem"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="是否需要回执" :label-width="formLabelWidth">
          <el-select v-model="form.status" placeholder="" size="small">
            <el-option label="是" value="是"></el-option>
            <el-option label="否" value="否"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" type="primary" @click="onSubmit"
              >保存</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getPass_api } from "../../../apis/hotel";
export default {
  props: {
    getAudit: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        status: "",
      },
      formLabelWidth: "120px",
      id: "",
    };
  },
  methods: {
    open(id) {
      this.dialogFormVisible = true;
      this.id = id;
    },
    //确认保存
    onSubmit() {
      this.pass();
    },
    close() {
      this.dialogFormVisible = false;
    },
    // 确认审核逻辑
    async pass() {
      let { code, data } = await getPass_api({
        hotelSonId: this.id,
        isOk: 1,
        receiptType: this.form.status == "是" ? "1" : "0",
        isOkMsg: "",
      });
      if (code == 0) {
        console.log(data, "审核通过");
        this.dialogFormVisible = false;
        this.$message({
          message: "审核通过",
          type: "success",
        });
        this.getAudit();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-form {
  width: 70%;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: space-around;
}
/deep/.el-input__inner {
  background-color: #f1f1f1;
}
</style>