<template>
  <div class="reviseOrder">
    <el-dialog
      title="交接酒店"
      :visible.sync="dialogFormVisible"
      width="35%"
      top="15rem"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="组别" :label-width="formLabelWidth">
          <el-select
            size="small"
            @change="getPerson"
            v-model="form.group"
            placeholder="请选择类型"
          >
            <el-option
              v-for="item in groupOptions"
              :key="item.id"
              :label="item.groupName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="人员" :label-width="formLabelWidth">
          <el-select size="small" v-model="personId" placeholder="请选择类型">
            <el-option
              v-for="item in personOptions"
              :key="item.id"
              :label="item.userName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button size="small" type="primary" plain @click="close"
              >取消</el-button
            >
            <el-button size="small" type="primary" @click="onSubmit"
              >保存</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  gethotelHandover_api,
  sethotelHandover_api,
} from "../../../apis/hotel";
export default {
  props: {
    getGenList: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        group: "", // 组别
        person: "", // 人员
      },
      personId: "", // id
      formLabelWidth: "80px",
      // 组别选择列表
      groupOptions: [],
      // 人员选择列表
      personOptions: [],
      hotelObj: "", // 存储酒店对象
      hotelSonIds:[],
    };
  },
  methods: {
    // 获取组别和人员的列表数据功能函数
    async getGroupPersonLIst() {
      let { data } = await gethotelHandover_api({
        type: 0,
      });
      console.log(data.records, "组别人员");
      this.groupOptions = data.records; // 赋值
    },
    // 获取当前选择组别
    getPerson(val) {
      this.form.group = "";
      this.personId = "";
      this.personOptions = [];
      this.form.group = val;
      let result = this.groupOptions.filter((item) => {
        if (item.id == val) {
          return item;
        }
      });
      this.personOptions = result[0].managerList; // 赋值
    },
    // 设置交接人员功能函数
    async settingHandoverPersonFn() {
      let { code, data, msg } = await sethotelHandover_api({
        groupId: this.form.group,
        managerId: this.personId,
        hotelSonId: this.hotelSonIds,
      });
      console.log(data, code, "响应数据");
      if (code == 0 || msg == "调用成功") {
        this.$message({
          type: "success",
          message: "恭喜您，交接成功!",
        });
        this.getGenList(); // 重绘视图
      } else {
        this.$message({
          type: "info",
          message: msg,
        });
      }
    },
    // 打开dialog弹窗的数据回显
    open(hotelSonIds) {
      //console.log(row, "哈哈哈哈");
     // this.hotelObj = row[0];
     this.hotelSonIds=hotelSonIds
      this.dialogFormVisible = true;
      this.getGroupPersonLIst(); // 调用获取组别功能函数
    },
    //确认保存
    onSubmit() {
      this.settingHandoverPersonFn(); // 调用保存转接功能函数
      this.form = {};
      this.dialogFormVisible = false;
    },
    // 关闭弹窗
    close() {
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input__inner {
  width: 260px;
  height: 40px;
}
/deep/.el-dialog {
  border-radius: 10px;
}
.el-form {
  width: 70%;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: space-around;
}
/deep/.el-input__inner {
  background-color: #f1f1f1;
}
</style>