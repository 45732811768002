/*飞猪匹配弹窗 */ 
<template>
  <div>
    <el-dialog
      title="输入酒店名称"
      :visible.sync="dialogTableVisible"
      width="57.29%"
    >
      <div class="title">
        <div>
          <span style="width: 35px">城市</span>
          <div style="width: 80px">
            <el-input v-model="cityName" size="small"></el-input>
          </div>
        </div>
        <div style="width: 160px; margin-left: 20px">
          <el-input
            v-model="ctripHotelName"
            placeholder="输入酒店名称"
            prefix-icon="el-icon-search"
            size="small"
          ></el-input>
        </div>
        <div style="margin-left: 20px">
          <span style="width: 65px">详细地址</span>
          <div style="width: 160px">
            <el-input
              v-model="address"
              placeholder="输入酒店详细地址"
              size="small"
            ></el-input>
          </div>
        </div>
        <div style="margin-left: 20px">
          <span style="width: 35px">经度</span>
          <div style="width: 80px">
            <el-input v-model="lng" size="small"></el-input>
          </div>
        </div>
        <div style="margin-left: 20px">
          <span style="width: 35px">纬度</span>
          <div style="width: 80px">
            <el-input v-model="lat" size="small"></el-input>
          </div>
        </div>
        <el-button
          type="primary"
          size="small"
          style="margin-left: 40px"
          @click="search"
          >搜索</el-button
        >
      </div>
      <el-table :data="gridData">
        <el-table-column property="shid" label="酒店ID"></el-table-column>
        <el-table-column
          property="name"
          label="城市/酒店名称"
          width="150"
        ></el-table-column>
        <el-table-column
          property="address"
          label="酒店地址"
          width="200"
        ></el-table-column>
        <el-table-column property="tel" label="电话"></el-table-column>
        <el-table-column
          property="jwd"
          label="经纬度"
          width="180"
        ></el-table-column>
        <el-table-column property="score" label="相似度">
          <template slot-scope="scope">
            <div>{{ Math.floor(scope.row.score) }}%</div>
          </template>
        </el-table-column>
        <el-table-column property="similar" label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="manualFeizhu(scope.row)"
              >匹配</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import {
  getFeizhuMatchingList_api,
  manualFeizhu_api,
} from "../../../apis/hotel";
export default {
  props: {
    getMatchList: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      // 列表数据
      gridData: [],
      dialogTableVisible: false,
      cityName: "", //城市名字
      ctripHotelName: "", //酒店名字
      address: "", //地址
      lat: "",
      lng: "",
      list: [],
    };
  },
  methods: {
    open(row) {
      // console.log(row);
      this.address = row.address;
      this.ctripHotelName = row.ctripHotelName;
      this.cityName = row.cityName;
      this.list = row;
      this.dialogTableVisible = true;
      this.getList();
    },
    // 获取匹配列表
    async getList() {
      let { code, data } = await getFeizhuMatchingList_api({
        hotelName: this.ctripHotelName,
        cityName: this.cityName,
        address: this.address,
        hotelSonVestId: this.list.id,
        longitude: this.lng,
        latitude: this.lat,
      });
      if (code == 0) {
        // console.log(data, "222222222");
        this.gridData = data;
        console.log(this.gridData);
      }
    },
    // 搜索
    search() {
      this.getList();
    },
    // 匹配
    async manualFeizhu(row) {
      console.log(row);
      let { code, data } = await manualFeizhu_api({
        shid: row.shid,
        hotelSonVestId: this.list.id,
      });
      if (code == 0) {
        this.$message({
          message: "匹配成功",
          type: "success",
        });
        this.getMatchList();
      } else {
        this.$message({
          message: "匹配失败",
          type: "info",
        });
        this.getMatchList();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  display: flex;
  margin-bottom: 20px;
  div {
    display: flex;
    align-items: center;
    span {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }
  }
}
/deep/.el-input--small .el-input__inner {
  background: #f6f8fb !important;
}
/deep/.el-dialog {
  border-radius: 20px;
}
/deep/.el-dialog__body {
  padding-top: 20px !important;
}
/deep/.el-table tr {
  background: #fafcff;
}
</style>