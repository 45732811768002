/*去哪儿匹配弹窗 */ 
<template>
  <div>
    <el-dialog
      title="自动匹配"
      :visible.sync="dialogFormVisible"
      width="28%"
      top="10rem"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="酒店名称" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.name"
            autocomplete="off"
            placeholder="输入匹配酒店名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button size="small" type="primary" plain @click="close"
              >取消</el-button
            >
            <el-button size="small" type="primary" @click="hold"
              >匹配</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { manualMatching_api } from "../../../apis/hotel.js";
export default {
  props: {
    getMatchList: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        name: "",
      },
      formLabelWidth: "100px",
      list: [],
    };
  },
  methods: {
    open(row) {
      console.log(row);
      this.list = row;
      this.dialogFormVisible = true;
    },
    // 匹配
    async hold() {
      let { code, data } = await manualMatching_api({
        hotelSonVestId: this.list.id,
        matching: 1,
        matchingHotelId: "",
        matchingHotelName: this.form.name,
      });
      if (code == 0) {
        // console.log(data)
        this.$message({
          message: "匹配成功",
          type: "success",
        });
        this.getMatchList();
        this.dialogFormVisible = false;
      }
    },
    // 取消
    close() {
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.el-form {
  width: 70%;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: space-around;
  padding-top: 10px;
  box-sizing: border-box;
}
/deep/.el-dialog {
  border-radius: 20px;
}
</style>