<template>
  <!-- 发布酒店 -->
  <div>
    <el-dialog title="发布酒店" width="1100px" :visible.sync="dialogFormVisible" top="10rem" :close-on-click-modal="false"
      @closed="cleanDataFn">
      <div class="leftbox">选择平台</div>
      <div class="rightbox">
        <div style="margin-bottom: 10px">
          <el-checkbox style="font-weight: 400" :indeterminate="indeterminate" v-model="ischeckAll"
            @change="handleCheckAllChange">
            全选
          </el-checkbox>
          <el-checkbox style="font-weight: 400" v-model="fanCheck" @change="handFanCheck">
            反选
          </el-checkbox>
        </div>
        <el-row>
          <el-col :span="2">
            <div class="channelTitle">
              <el-checkbox style="font-weight: 400" :indeterminate="indeterminateForCtrip" v-model="ischeckAllForCtrip"
                @change="handleCheckAllChangeForCtrip">
                携程
              </el-checkbox>
            </div>
            <div class="marginbottom10" v-for="item in detailData.ctrip" :key="item.id">
              <el-checkbox @change="handleCheckChangeForCtrip" v-model="item.selected" :disabled="item.isLock">{{
      item.horseName }}</el-checkbox>
            </div>
          </el-col>
          <el-col :offset="1" :span="2">
            <div class="channelTitle">
              <el-checkbox style="font-weight: 400" :indeterminate="indeterminateForSameCity"
                v-model="ischeckAllForSameCity" @change="handleCheckAllChangeForSameCity">
                艺龙
              </el-checkbox>
            </div>
            <div class="marginbottom10" v-for="item in detailData.sameCity" :key="item.id">
              <el-checkbox @change="handleCheckChangeForSameCity" v-model="item.selected" :disabled="item.isLock">{{
      item.horseName }}</el-checkbox>
            </div>
          </el-col>
          <el-col :offset="1" :span="2">
            <div class="channelTitle">
              <el-checkbox style="font-weight: 400" :indeterminate="indeterminateForQunar" v-model="ischeckAllForQunar"
                @change="handleCheckAllChangeForQunar">
                去哪
              </el-checkbox>
            </div>
            <div class="marginbottom10" v-for="item in detailData.goWhere" :key="item.id">
              <el-checkbox @change="handleCheckChangeForQunar" v-model="item.selected" :disabled="item.isLock">{{
      item.horseName }}</el-checkbox>
            </div>
          </el-col>
          <el-col :offset="1" :span="2">
            <div class="channelTitle">
              <el-checkbox style="font-weight: 400" :indeterminate="indeterminateForMeituan"
                v-model="ischeckAllForMeituan" @change="handleCheckAllChangeForMeituan">
                美团
              </el-checkbox>
            </div>
            <div class="marginbottom10" v-for="item in detailData.meiTuan" :key="item.id">
              <el-checkbox @change="handleCheckChangeForMeituan" v-model="item.selected" :disabled="item.isLock">{{
      item.horseName }}</el-checkbox>
            </div>
          </el-col>
          <el-col :offset="1" :span="2">
            <div class="channelTitle">
              <el-checkbox style="font-weight: 400" :indeterminate="indeterminateForFliggy"
                v-model="ischeckAllForFliggy" @change="handleCheckAllChangeForFliggy">
                飞猪
              </el-checkbox>
            </div>
            <div class="marginbottom10" v-for="item in detailData.filPig" :key="item.id">
              <el-checkbox @change="handleCheckChangeForFliggy" v-model="item.selected" :disabled="item.isLock">{{
      item.horseName }}</el-checkbox>
            </div>
          </el-col>
          <el-col :offset="1" :span="2">
            <div class="channelTitle">
              <el-checkbox style="font-weight: 400" :indeterminate="indeterminateForBaidu" v-model="ischeckAllForBaidu"
                @change="handleCheckAllChangeForBaidu">
                百度
              </el-checkbox>
            </div>
            <div class="marginbottom10" v-for="item in detailData.baidu" :key="item.id">
              <el-checkbox @change="handleCheckChangeForBaidu" v-model="item.selected" :disabled="item.isLock">{{
      item.horseName }}</el-checkbox>
            </div>
          </el-col>
          <el-col :offset="1" :span="2">
            <div class="channelTitle">
              <el-checkbox style="font-weight: 400" :indeterminate="indeterminateForLuobo" v-model="ischeckAllForLuobo"
                @change="handleCheckAllChangeForLuobo">
                萝卜
              </el-checkbox>
            </div>
            <div class="marginbottom10" v-for="item in detailData.luobo" :key="item.id">
              <el-checkbox @change="handleCheckChangeForLuobo" v-model="item.selected" :disabled="item.isLock">{{
      item.horseName }}</el-checkbox>
            </div>
          </el-col>
          <el-col :offset="1" :span="2">
            <div class="channelTitle">
              <el-checkbox style="font-weight: 400" :indeterminate="indeterminateForFangcang"
                v-model="ischeckAllForFangcang" @change="handleCheckAllChangeForFangcang">
                天下房仓
              </el-checkbox>
            </div>
            <div class="marginbottom10" v-for="item in detailData.fctx" :key="item.id">
              <el-checkbox @change="handleCheckChangeForFangcang" v-model="item.selected" :disabled="item.isLock">{{
      item.horseName }}</el-checkbox>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="diafooterbtn">
        <el-button @click="cleanFn" style="width: 84px; margin-right: 10%" size="small" type="primary"
          plain>取消</el-button>
        <el-button style="width: 84px" @click="handEditData" :disabled="isHanding" size="small"
          type="primary">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getReleasePlatformVestList } from "../../../apis/hotel";
import { setReleasePlatformVestList } from "../../../apis/hotel";
export default {
  data() {
    return {
      dialogFormVisible: false,
      //
      fanCheck: false,
      arrRoomBigList: [],
      //
      indeterminate: true,
      weekIndeterminate: true,
      //
      ischeckAll: false,
      allState: "不变",
      state: 0,
      ischeckAllWeek: false,
      checkedWeeks: [],
      ischeckAllChannel: false,
      channelIndeterminate: true,
      checkedChannels: [],

      hotelSonId: "",
      detailData: "",

      indeterminateForCtrip: true, //携程
      ischeckAllForCtrip: false, //携程
      indeterminateForSameCity: true, //艺龙
      ischeckAllForSameCity: false, //艺龙
      indeterminateForQunar: true, //去哪儿
      ischeckAllForQunar: false, //去哪儿
      indeterminateForMeituan: true, //美团
      ischeckAllForMeituan: false, //美团
      indeterminateForFliggy: true,//飞猪
      ischeckAllForFliggy: false,//飞猪
      indeterminateForBaidu: true,//百度
      ischeckAllForBaidu: false,//百度
      indeterminateForLuobo: true,//萝卜
      ischeckAllForLuobo: false,//萝卜
      indeterminateForFangcang: true,//天下房仓
      ischeckAllForFangcang: false,//天下房仓

      hotelName: '',
      isHanding: false,
    };
  },
  methods: {
    open(id, hotelName) {
      this.getReleasePlatformVestListFn(id);
      this.hotelSonId = id;
      this.hotelName = hotelName

      this.dialogFormVisible = true;
    },
    async getReleasePlatformVestListFn(id) {
      let { code, data } = await getReleasePlatformVestList({
        hotelSonId: id,
      });
      if (code == 0) {
        if (data.ctrip) {
          data.ctrip.forEach((item) => {
            if (item.selected == true) {
              this.$set(item, "isLock", true);
            }
          });
        }
        if (data.sameCity) {
          data.sameCity.forEach((item) => {
            if (item.selected == true) {
              this.$set(item, "isLock", true);
            }
          });
        }
        if (data.goWhere) {
          data.goWhere.forEach((item) => {
            if (item.selected == true) {
              this.$set(item, "isLock", true);
            }
          });
        }
        if (data.meiTuan) {
          data.meiTuan.forEach((item) => {
            if (item.selected == true) {
              this.$set(item, "isLock", true);
            }
          });
        }
        if (data.filPig) {
          data.filPig.forEach((item) => {
            if (item.selected == true) {
              this.$set(item, "isLock", true);
            }
          });
        }
        if (data.baidu) {
          data.baidu.forEach((item) => {
            if (item.selected == true) {
              this.$set(item, "isLock", true);
            }
          });
        }
        if (data.luobo) {
          data.luobo.forEach((item) => {
            if (item.selected == true) {
              this.$set(item, "isLock", true);
            }
          });
        }
        if (data.fctx) {
          data.fctx.forEach((item) => {
            if (item.selected == true) {
              this.$set(item, "isLock", true);
            }
          });
        }


        this.detailData = data;
      }
    },

    //一级全选
    handleCheckAllChange(val) {
      //  console.log("最外层全选");
      this.handleCheckAllChangeForCtrip(val);
      this.handleCheckAllChangeForSameCity(val);
      this.handleCheckAllChangeForQunar(val);
      this.handleCheckAllChangeForMeituan(val);
      this.handleCheckAllChangeForFliggy(val);
      this.handleCheckAllChangeForBaidu(val);
      this.handleCheckAllChangeForLuobo(val)
      this.handleCheckAllChangeForFangcang(val)
      this.indeterminate = false;
      if (val) {
        this.ischeckAllForCtrip = true;
        this.ischeckAllForSameCity = true;
        this.ischeckAllForQunar = true;
        this.ischeckAllForMeituan = true;
        this.ischeckAllForFliggy = true;
        this.ischeckAllForBaidu = true;
        this.ischeckAllForLuobo = true;
        this.ischeckAllForFangcang = true;

      } else {
        this.ischeckAllForCtrip = false;
        this.ischeckAllForSameCity = false;
        this.ischeckAllForQunar = false;
        this.ischeckAllForMeituan = false;
        this.ischeckAllForFliggy = false;
        this.ischeckAllForBaidu = false;
        this.ischeckAllForLuobo = false;
        this.ischeckAllForFangcang = false;

      }
      this.getIsCheckAll();
    },

    handFanCheck(val) {
      //  console.log("反选");
      this.detailData.ctrip.forEach((item) => {
        if (!item.isLock) {
          item.selected = !item.selected;
        }
      });
      this.detailData.sameCity.forEach((item) => {
        if (!item.isLock) {
          item.selected = !item.selected;
        }
      });
      this.detailData.goWhere.forEach((item) => {
        if (!item.isLock) {
          item.selected = !item.selected;
        }
      });
      this.detailData.meiTuan.forEach((item) => {
        if (!item.isLock) {
          item.selected = !item.selected;
        }
      });
      this.detailData.filPig.forEach((item) => {
        if (!item.isLock) {
          item.selected = !item.selected;
        }
      });
      this.detailData.baidu.forEach((item) => {
        if (!item.isLock) {
          item.selected = !item.selected;
        }
      });
      this.detailData.luobo.forEach((item) => {
        if (!item.isLock) {
          item.selected = !item.selected;
        }
      });
      this.detailData.fctx.forEach((item) => {
        if (!item.isLock) {
          item.selected = !item.selected;
        }
      });

      //  this.getIsCheckAll();
      this.handleCheckChangeForCtrip();
      this.handleCheckChangeForSameCity();
      this.handleCheckChangeForQunar();
      this.handleCheckChangeForMeituan();
      this.handleCheckChangeForFliggy();
      this.handleCheckChangeForBaidu();
      this.handleCheckChangeForLuobo();
      this.handleCheckChangeForFangcang();

    },
    handleCheckAllChangeForCtrip(val) {
      //   console.log("携程全选");

      this.detailData.ctrip.forEach((item) => {
        if (val) {
          item.selected = true;
          this.indeterminateForCtrip = false;
        } else {
          if (!item.isLock) {
            item.selected = false;
          }
          // if(count==this.detailData.ctrip.length){
          //    this.indeterminateForCtrip = false;
          // }else if(count==0){
          //   item.selected = true;
          // this.indeterminateForCtrip = false;
          // }else{
          //    item.selected = true;
          // this.indeterminateForCtrip = false;
          // }
        }
      });
      this.getIsCheckAll();
    },
    handleCheckAllChangeForSameCity(val) {
      //   console.log("艺龙全选");
      this.detailData.sameCity.forEach((item) => {
        if (val) {
          item.selected = true;
          this.indeterminateForSameCity = false;
        } else {
          if (!item.isLock) {
            item.selected = false;
          }
        }
      });
      this.getIsCheckAll();
    },
    handleCheckAllChangeForQunar(val) {
      //  console.log("去哪儿全选");
      this.detailData.goWhere.forEach((item) => {
        if (val) {
          item.selected = true;
          this.indeterminateForQunar = false;
        } else {
          if (!item.isLock) {
            item.selected = false;
          }
        }
      });
      this.getIsCheckAll();
    },
    handleCheckAllChangeForMeituan(val) {
      //  console.log("美团全选");
      this.detailData.meiTuan.forEach((item) => {
        if (val) {
          item.selected = true;
          this.indeterminateForMeituan = false;
        } else {
          if (!item.isLock) {
            item.selected = false;
          }
        }
      });
      this.getIsCheckAll();
    },
    handleCheckAllChangeForFliggy(val) {
      // console.log("飞猪全选");
      this.detailData.filPig.forEach((item) => {
        if (val) {
          item.selected = true;
          this.indeterminateForFliggy = false;
        } else {
          if (!item.isLock) {
            item.selected = false;
          }
        }
      });
      this.getIsCheckAll();
    },
    handleCheckAllChangeForBaidu(val) {
      // console.log("百度全选");
      this.detailData.baidu.forEach((item) => {
        if (val) {
          item.selected = true;
          this.indeterminateForBaidu = false;
        } else {
          if (!item.isLock) {
            item.selected = false;
          }
        }
      });
      this.getIsCheckAll();
    },
    handleCheckAllChangeForLuobo(val) {
      // console.log("萝卜全选");
      this.detailData.luobo.forEach((item) => {
        if (val) {
          item.selected = true;
          this.indeterminateForLuobo = false;
        } else {
          if (!item.isLock) {
            item.selected = false;
          }
        }
      });
      this.getIsCheckAll();
    },

    handleCheckAllChangeForFangcang(val) {
      // console.log("天下房仓全选");
      this.detailData.fctx.forEach((item) => {
        if (val) {
          item.selected = true;
          this.indeterminateForFangcang = false;
        } else {
          if (!item.isLock) {
            item.selected = false;
          }
        }
      });
      this.getIsCheckAll();
    },

    handleCheckChangeForCtrip(val) {
      // console.log("携程单选");
      let count = 0;
      this.detailData.ctrip.forEach((item) => {
        if (item.selected == true) {
          count = count + 1;
        }
      });
      if (count == 0) {
        this.indeterminateForCtrip = false;
        this.ischeckAllForCtrip = false;
      } else if (count == this.detailData.ctrip.length) {
        this.indeterminateForCtrip = false;
        this.ischeckAllForCtrip = true;
      } else {
        this.indeterminateForCtrip = true;
        this.ischeckAllForCtrip = false;
      }
      this.getIsCheckAll();
    },
    handleCheckChangeForSameCity(val) {
      // console.log("艺龙单选");
      let count = 0;
      this.detailData.sameCity.forEach((item) => {
        if (item.selected == true) {
          count = count + 1;
        }
      });
      if (count == 0) {
        this.indeterminateForSameCity = false;
        this.ischeckAllForSameCity = false;
      } else if (count == this.detailData.sameCity.length) {
        this.indeterminateForSameCity = false;
        this.ischeckAllForSameCity = true;
      } else {
        this.indeterminateForSameCity = true;
        this.ischeckAllForSameCity = false;
      }
      this.getIsCheckAll();
    },
    handleCheckChangeForQunar() {
      //  console.log("去哪单选");
      let count = 0;
      this.detailData.goWhere.forEach((item) => {
        if (item.selected == true) {
          count = count + 1;
        }
      });
      if (count == 0) {
        this.indeterminateForQunar = false;
        this.ischeckAllForQunar = false;
      } else if (count == this.detailData.goWhere.length) {
        this.indeterminateForQunar = false;
        this.ischeckAllForQunar = true;
      } else {
        this.indeterminateForQunar = true;
        this.ischeckAllForQunar = false;
      }
      this.getIsCheckAll();
    },
    handleCheckChangeForMeituan() {
      // console.log("美团单选");
      let count = 0;
      this.detailData.meiTuan.forEach((item) => {
        if (item.selected == true) {
          count = count + 1;
        }
      });
      if (count == 0) {
        this.indeterminateForMeituan = false;
        this.ischeckAllForMeituan = false;
      } else if (count == this.detailData.meiTuan.length) {
        this.indeterminateForMeituan = false;
        this.ischeckAllForMeituan = true;
      } else {
        this.indeterminateForMeituan = true;
        this.ischeckAllForMeituan = false;
      }
      this.getIsCheckAll();
    },
    handleCheckChangeForFliggy() {
      //  console.log("飞猪单选");
      let count = 0;
      this.detailData.filPig.forEach((item) => {
        if (item.selected == true) {
          count = count + 1;
        }
      });
      if (count == 0) {
        this.indeterminateForFliggy = false;
        this.ischeckAllForFliggy = false;
      } else if (count == this.detailData.filPig.length) {
        this.indeterminateForFliggy = false;
        this.ischeckAllForFliggy = true;
      } else {
        this.indeterminateForFliggy = true;
        this.ischeckAllForFliggy = false;
      }
      this.getIsCheckAll();
    },
    handleCheckChangeForBaidu() {
      //  console.log("百度单选");
      let count = 0;
      this.detailData.baidu.forEach((item) => {
        if (item.selected == true) {
          count = count + 1;
        }
      });
      if (count == 0) {
        this.indeterminateForBaidu = false;
        this.ischeckAllForBaidu = false;
      } else if (count == this.detailData.baidu.length) {
        this.indeterminateForBaidu = false;
        this.ischeckAllForBaidu = true;
      } else {
        this.indeterminateForBaidu = true;
        this.ischeckAllForBaidu = false;
      }
      this.getIsCheckAll();
    },
    handleCheckChangeForLuobo() {
      //  console.log("萝卜单选");
      let count = 0;
      this.detailData.luobo.forEach((item) => {
        if (item.selected == true) {
          count = count + 1;
        }
      });
      if (count == 0) {
        this.indeterminateForLuobo = false;
        this.ischeckAllForLuobo = false;
      } else if (count == this.detailData.luobo.length) {
        this.indeterminateForLuobo = false;
        this.ischeckAllForLuobo = true;
      } else {
        this.indeterminateForLuobo = true;
        this.ischeckAllForLuobo = false;
      }
      this.getIsCheckAll();
    },

    handleCheckChangeForFangcang() {
      //  console.log("天下房仓单选");
      let count = 0;
      this.detailData.fctx.forEach((item) => {
        if (item.selected == true) {
          count = count + 1;
        }
      });
      if (count == 0) {
        this.indeterminateForFangcang = false;
        this.ischeckAllForFangcang = false;
      } else if (count == this.detailData.fctx.length) {
        this.indeterminateForFangcang = false;
        this.ischeckAllForFangcang = true;
      } else {
        this.indeterminateForFangcang = true;
        this.ischeckAllForFangcang = false;
      }
      this.getIsCheckAll();
    },


    //选择引起的上层全选框变动
    getIsCheckAll() {
      if (
        this.ischeckAllForCtrip == true &&
        this.ischeckAllForSameCity == true &&
        this.ischeckAllForQunar == true &&
        this.ischeckAllForMeituan == true &&
        this.ischeckAllForFliggy == true &&
        this.ischeckAllForBaidu == true &&
        this.ischeckAllForLuobo == true &&
        this.ischeckAllForFangcang == true
      ) {
        this.ischeckAll = true;
        this.indeterminate = false;
      } else if (
        this.ischeckAllForCtrip == false &&
        this.ischeckAllForSameCity == false &&
        this.ischeckAllForQunar == false &&
        this.ischeckAllForMeituan == false &&
        this.ischeckAllForFliggy == false &&
        this.ischeckAllForBaidu == false &&
        this.ischeckAllForLuobo == false &&
        this.ischeckAllForFangcang == false

      ) {
        this.ischeckAll = false;
        this.indeterminate = false;
      } else {
        this.ischeckAll = false;
        this.indeterminate = true;
      }
    },
    // 取消
    cleanFn() {
      this.dialogFormVisible = false;
    },
    // 提交
    async handEditData() {
      this.isHanding = true
      let { code, data } = await setReleasePlatformVestList({
        hotelSonId: this.hotelSonId,
        getHotelSonVestMapVo: this.detailData,
      });

      if (code == 0) {
        this.isHanding = false
        this.$message.success(data ? data : "提交成功");
        this.dialogFormVisible = false;
        let newHotelName = this.hotelName.split('/')[1]
        console.log(newHotelName)

        this.$parent.$parent.handleSelect2(newHotelName)

      } else {
        this.isHanding = false
      }
    },
    cleanDataFn() {
      Object.assign(this.$data, this.$options.data());
    },
  },
};
</script>

<style lang="less" scoped>
.leftbox {
  display: inline-block;
  width: 50px;
  vertical-align: top;
 // margin-left: 30px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  margin-bottom: 20px;
}

.rightbox {
  display: inline-block;
  width: 970px;
 //flex: 1;
  vertical-align: top;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  padding-left: 20px;
}

.minddlebox {
  display: inline-block;
  // width: 74%;
  vertical-align: top;
  padding-left: 20px;
}

.rightbtnbox {
  display: inline-block;
  vertical-align: top;
}

.rightboxcontentbox {
  display: inline-block;
  width: 45%;
  vertical-align: top;
}

.width96 {
  width: 96px;
}

.width60 {
  width: 60px;
  margin: 0 10px;
}

.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}

.hotelTitleName {
  margin-bottom: 15px;
  margin: 7.5px 0;

  /deep/ .el-checkbox__label {
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #666666;
    line-height: 18px;
  }
}

/deep/ .el-checkbox__label {
  font-size: 12px;
}

/deep/.el-checkbox__input.is-checked+.el-checkbox__label {
  color: #409eff;
}

.tableHead {
  background: #eeeeee;
  height: 40px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #666666;
  line-height: 40px;
  // margin-top: 30px;
}

.tableItem {
  height: 40px;
  line-height: 40px;
  border: 1px solid #f4f4f4;
  margin-left: 30px;
}

.tableChannel {
  margin-left: 12px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #666666;
}

.rommListBox {
  height: 150px;
  overflow: auto;
}

.channelsListBox {
  height: 300px;
  overflow: auto;
  margin-top: 30px;
}

.channelTitle {
  margin-bottom: 15px;
  font-weight: bold;
}

.marginbottom10 {
  margin-left: 10px;
  margin-bottom: 10px;
}
</style>>
