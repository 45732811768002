<template>
  <!-- 批量申请 -->
  <div class="offlineApply">
    <el-dialog
      title="通过"
      :visible.sync="dialogFormVisible"
      width="35%"
      top="10rem"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
       <el-form-item label="保留天数" :label-width="formLabelWidth">
           <el-input
            size="small"
            v-model="form.retentionDays"
          ></el-input>

        </el-form-item>
        <el-form-item label="通过理由" :label-width="formLabelWidth">
          <el-input
            size="small"
            type="textarea"
            v-model="form.description"
            :rows="3"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" type="primary" @click="onSubmit"
              >保存</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    getOffLine: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        description: "",
        retentionDays:'',
      },
      formLabelWidth: "80px",
      hotelId: "",
    };
  },
  methods: {
    open(hotelId) {
      this.hotelId = hotelId;
      this.dialogFormVisible = true;
    },
    //确认提交
    onSubmit() {
      this.apply();
      this.getOffLine();
      this.dialogFormVisible = false;
    },
    close() {
      this.dialogFormVisible = false;
    },
    //申请下线
    apply() {
      this.$axios({
        method: "post",
        url: "/otaHotel/hotelSon/pass_or_reject",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          hotelSonId: this.hotelId,
          passOrReject: 1,
          passOrRejectMsg: this.form.description,
          retentionDays: this.form.retentionDays,
        },
      })
        .then((res) => {
          this.$message({
            type: "success",
            message: res.data.data,
          });
          if (res.data.code == 0) {
            this.getOffLine();
            this.dialogFormVisible = false;
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-form {
  width: 70%;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: space-around;
}
/deep/.el-input__inner {
  background-color: #f1f1f1;
}
</style>