<template>
  <div class="reviseOrder">
    <el-dialog
      title="批量移动"
      :visible.sync="dialogFormVisible"
      width="35%"
      top="15rem"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="移动位置" :label-width="formLabelWidth">
          <el-select size="small" v-model="form.address" placeholder="请选择类型">
            <el-option
              v-for="item in locationOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="运营助理" :label-width="formLabelWidth">
          <el-select size="small" @change="getOprateFn" v-model="form.person" placeholder="请选择类型">
            <el-option
              v-for="(item,index) in personOptions"
              :key="item.operateManagerId"
              :label="item.roleName"
              :value="index"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button size="small" type="primary" plain @click="close"
              >取消</el-button
            >
            <el-button size="small" type="primary" @click="onSubmit"
              >保存</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {getOperationsAssistant_api,setOperationsAssistant_api} from '../../../apis/hotel'
export default {
   props: {
    getCoreLyHotelListFn: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        address: "", // 移动位置
        person: "", //  人员
      },
      hotelIdArr:[], // 存放运营人员id的数组
      oprateObj:{}, // 对象
      formLabelWidth: "80px",
      // 移动位置
      // locationOptions: [
      //   {value:0,label:'产品'},
      //   {value:1,label:'运营'},
      //   {value:2,label:'售前'},
      //   {value:3,label:'售后'},
      //   {value:4,label:'二次确认'},
      // ],
      // 移动位置
      locationOptions: [
        {value:0,label:'核心酒店'},
        {value:1,label:'一般酒店'},
        {value:2,label:'待下线酒店'},
        // {value:3,label:'待开发酒店'},
        // {value:4,label:'审核酒店'},
        {value:5,label:'黑名单酒店'},
      ],
      personOptions: [], // 运营助理
    };
  },
  created() {},
  methods: {
    // 获取运营列表功能函数
    async getOpratePersonListFn(){
      let {data}=await getOperationsAssistant_api();
      this.personOptions=data; // 赋值
    },
    // 选择运营助理
    getOprateFn(index){
      this.oprateObj=this.personOptions[index];
      console.log(this.oprateObj,'选中的运营人员');
    },
    // 设置运营助理功能函数
    async settingOprateFn(){
      let {code,data}=await setOperationsAssistant_api({
        hotelSonIds:this.hotelIdArr.toString(),
        hotelType:this.form.address,
        operateManagerId:this.oprateObj.operateManagerId,
        groupId:this.oprateObj.groupId,
      });
      if(code==0||data=='批量移动成功！'){
        this.$message({
          type:'success',
          message:data,
        });
        this.getCoreLyHotelListFn(); // 重回视图
      }else{
        this.$message({
          type:'info',
          message:data,
        })
      }
    },
    // 打开dialog弹窗的数据回显
    open(data) {
      this.hotelIdArr=[]; // 保存
      data.forEach(item=>{
        this.hotelIdArr.push(item.hotelId);
      });
      console.log(this.hotelIdArr.toString(),'选中的酒店id'); // 酒店id存放数组
      this.dialogFormVisible = true;
      this.getOpratePersonListFn(); // 调用获取运营列表接口
    },
    //确认保存
    onSubmit() {
      this.settingOprateFn(); // 调用保存数据功能函数
      this.form = {};
      this.dialogFormVisible = false;
    },
    // 关闭弹窗
    close() {
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input__inner {
  width: 260px;
  height: 40px;
}
/deep/.el-dialog {
  border-radius: 10px;
}
.el-form {
  width: 70%;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: space-around;
}
/deep/.el-input__inner {
  background-color: #f1f1f1;
}
</style>