<template>
  <div class="reviseOrder">
    <el-dialog
      title="设置时间"
      :visible.sync="dialogFormVisible"
      width="35%"
      top="15rem"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="类型" :label-width="formLabelWidth">
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button size="small" type="primary" plain @click="close"
              >取消</el-button
            >
            <el-button size="small" type="primary" @click="onSubmit"
              >保存</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getTimeLength_apiak } from "../../../apis/basic";
export default {
  props: {},
  data() {
    return {
      dialogFormVisible: false,
      form: {
        channel: "", // 类型
      },
      formLabelWidth: "",
      //下拉框s选择
      options: [
        {
          value: 1,
          label: "当日",
        },
        {
          value: 2,
          label: "次日",
        },
      ],
    };
  },
  created() {},
  methods: {
    // 打开dialog弹窗的数据回显
    open(row) {
      this.dialogFormVisible = true;
      console.log(row, "修改超时订单的当前行数据");
    },
    //确认保存
    onSubmit() {
      this.form = {};
      this.dialogFormVisible = false;
    },
    close() {
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
// ::v-deep.el-input__inner{
//     width: 200px !important;
// }
/deep/.el-dialog {
  border-radius: 10px;
}
.el-form {
  width: 100%;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: space-around;
}
/deep/.el-input__inner {
  background-color: #f1f1f1;
}
</style>