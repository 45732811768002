<template>
  <!-- 修改弹窗 -->
  <div>
    <el-dialog title="酒店信息修改" :visible.sync="dialogVisible" width="900px">
      <el-form :inline="true" label-width="auto" :model="formInline" class="demo-form-inline">
        <el-form-item label="省份">
          <el-input size="small" clearable v-model="formInline.province" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="城市">
          <el-input size="small" clearable v-model="formInline.city" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input size="small" clearable v-model="formInline.address" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="经度">
          <el-input size="small" clearable v-model="formInline.longitude" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="纬度">
          <el-input size="small" clearable v-model="formInline.latitude" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="酒店ID">
          <el-input size="small" clearable v-model="formInline.ctripHotelId" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="酒店名称">
          <el-input size="small" clearable v-model="formInline.hotelName" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="星级">
          <el-input size="small" clearable v-model="formInline.star" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="标签">
          <el-select size="small" v-model="formInline.pag" :disabled="true">
            <el-option v-for="item in pag" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收款账户">
          <el-input size="small" clearable v-model="formInline.account" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="收款人">
          <el-input size="small" clearable v-model="formInline.paymentName" placeholder="收款人姓名"></el-input>
        </el-form-item>
        <el-form-item label="支付类型">
          <el-select size="small" v-model="formInline.paymentType" placeholder="请选择">
            <el-option v-for="item in paymentTypeOption" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="酒店联系人">
          <el-input size="small" clearable v-model="formInline.hotelContacts" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="开户行" v-show="formInline.paymentType == 2 || formInline.paymentType == '银行卡'
      ">
          <el-select size="small" filterable v-model="formInline.openingBankName" placeholder="请选择">
            <el-option v-for="item in openingBankNameOption" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系方式">
          <el-input size="small" clearable type="number" :disabled="!role_basics" v-model.trim="formInline.information"
            placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="运营助理">
          <el-select size="small" v-model="formInline.assistant">
            <el-option v-for="item in assistant" :key="item.id" :label="item.userName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="可发发票">
          <el-select size="small" v-model="formInline.isInvoice">
            <el-option v-for="item in isInvoiceOption" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="发单方式">
          <el-select size="small" @change="changeEmailType" v-model="formInline.emailType">
            <el-option v-for="item in emailTypeOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="付款方式">
          <el-select size="small" v-model="formInline.paymentMethod">
            <el-option v-for="item in paymentMethod" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-show="formInline.emailType == '邮箱' || formInline.emailType == 0" label="邮箱">
          <el-input size="small" clearable v-model="formInline.email" placeholder=""></el-input>
        </el-form-item>
        <el-form-item v-show="formInline.emailType == '小程序' || formInline.emailType == 2" label="酒店相关人员">
          <!-- <el-input
            size="small"
            clearable
            v-model="formInline.hotelManagerIds"
            placeholder=""
          ></el-input> -->
          <el-select size="small" multiple filterable :filter-method="queryFn" v-model="hotelManagerIds"
            placeholder="请选择" @change="changeHotelManagerIds">
            <el-option v-for="item in hotelManagerList" :key="item.id" :label="item.nickName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  serachTag_api,
  getBankList_api,
  gethotelManagerList_api,
} from "../../../apis/hotel";
import local from "@/utils/local.js";
import { getRoleKey } from "@/utils/rolekey"
export default {
  data() {
    return {
      dialogVisible: false,
      formInline: {
        province: "",
        city: "",
        hotelId: "",
        hotelName: "",
        star: "",
        pag: 0,
        account: "",
        hotelContacts: "",
        information: "",
        assistant: "",
        email: "",
        paymentMethod: "",
        emailType: "",
        address: "",
        wchatGroupName: "",
        email: "",
        isInvoice: "否",
        id: "",
        isSub: "",
        ctripHotelId: "",
        paymentType: "",
        paymentName: "",
        openingBankName: "",
        hotelManagerIds: "",
        latitude: "",
        longitude: "",
      },
      //   标签
      pag: [
        { value: "0", label: "一般酒店" },
        { value: "1", label: "核心酒店" },
      ],
      isInvoiceOption: [
        { value: "0", label: "否" },
        { value: "1", label: "是" },
      ],
      //   运营助理
      assistant: [],
      //   发单方式
      emailTypeOptions: [
        { value: "0", label: "邮箱" },
        { value: "1", label: "产品经理" },
        { value: "2", label: "小程序" },
        { value: "3", label: "后台发单" },
      ],
      //   付款方式
      paymentMethod: [
        { value: "0", label: "一单一结" },
        { value: "1", label: "预付款" },
      ],
      //收款类型
      paymentTypeOption: [
        {
          value: "1",
          label: "支付宝",
        },
        {
          value: "2",
          label: "银行卡",
        },
      ],
      hotelId: "",
      typeCore: "",
      openingBankNameOption: [],
      hotelManagerList: [],
      oldHotelManagerList: [],
      hotelManagerIds: [],
      role_basics: getRoleKey('role_basics'),
    };
  },
  created() {
    this.getBankList();
  },
  methods: {
    async getBankList() {
      let { code, data } = await getBankList_api();
      if (code == 0) {
        this.openingBankNameOption = data;
      }
    },
    open(hotelId, isSub) {
      //   console.log(row, "打开");
      this.gethotelManagerList();
      if (isSub == 1) {
        this.formInline.isSub = 1;
      } else {
        this.formInline.isSub = "";
      }
      this.hotelId = hotelId;
      this.getList();
      this.dialogVisible = true;

      this.tag();
    },
    //获取酒店相关人员列表
    async gethotelManagerList() {
      let { code, data } = await gethotelManagerList_api();
      if (code == 0) {
        this.hotelManagerList = data;
        this.oldHotelManagerList = JSON.parse(
          JSON.stringify(this.hotelManagerList)
        );
      }
    },
    changeHotelManagerIds() {
      console.log(this.hotelManagerIds);
      this.formInline.hotelManagerIds = this.hotelManagerIds.join(",");
    },
    // 获取运营助理
    async tag() {
      let { code, data } = await serachTag_api({
        typeCore: 0,
      });
      if (code == 0) {
        // console.log(data, "8520");
        this.assistant = data;
      }
    },
    // 确定
    sure() {
      let billType = "";
      console.log(this.formInline.emailType);
      if (this.formInline.emailType == "邮箱") {
        billType = 0;
      } else if (this.formInline.emailType == "产品经理") {
        billType = 1;
      } else if (this.formInline.emailType == "小程序") {
        billType = 2;
      } else if (this.formInline.emailType == "后台发单") {
        billType = 3;
      } else {
        billType = this.formInline.emailType;
      }
      let isInvoice;
      if (this.formInline.isInvoice == "否") {
        isInvoice = 0;
      } else if (this.formInline.isInvoice == "是") {
        isInvoice = 1;
      } else {
        isInvoice = this.formInline.isInvoice;
      }
      if (this.formInline.information == local.get('telephone')) {
        this.$message.warning('酒店电话不能是自己电话')
      } else {
        this.$axios({
          method: "post",
          url: "/product/hotel/updateHotel",
          data: {
            provinceName: this.formInline.province,
            cityName: this.formInline.city,
            address: this.formInline.address,
            ctripHotelId: this.formInline.ctripHotelId,
            ctripHotelName: this.formInline.hotelName,
            starRating: this.formInline.star,
            typeCore: this.formInline.pag == "一般酒店" ? 0 : 1,
            paymentAccount: this.formInline.account,
            contacts: this.formInline.hotelContacts,
            contactsTel: this.formInline.information,
            billType: billType,

            email: this.formInline.email,
            wchatGroupName: this.formInline.wchatGroupName,
            payType: this.formInline.paymentMethod == "一单一结" ? 0 : 1,

            operateManagerId: this.formInline.assistant,
            isInvoice: isInvoice,
            id: this.formInline.id,
            isSub: this.formInline.isSub,
            paymentType:
              this.formInline.paymentType == "支付宝"
                ? 1
                : this.formInline.paymentType == "银行卡"
                  ? 2
                  : this.formInline.paymentType,
            openingBankName: this.formInline.openingBankName,
            paymentName: this.formInline.paymentName,
            hotelManagerIds: this.formInline.hotelManagerIds,
            latitude: this.formInline.latitude,
            longitude: this.formInline.longitude,
          },
        })

          .then((res) => {
            console.log(res);
            if (res.data.code == 0) {
              this.$message.success(res.data.data ? res.data.data : "提交成功");
              this.dialogVisible = false;
            }
          })
          .catch((error) => { });

      }


    },
    changeEmailType(val) {
      console.log(val);
    },
    // 获取酒店信息
    getList() {
      this.$axios({
        method: "get",
        url: "/otaHotel/hotelSon/getHotelInfo?hotelSonId=" + this.hotelId,
        data: {},
      })
        .then((res) => {
          this.typeCore = res.data.typeCore;
          //  console.log(res, "222222222");
          this.formInline.province = res.data.data.provinceName;
          this.formInline.city = res.data.data.cityName;
          this.formInline.ctripHotelId = res.data.data.ctripHotelId;
          this.formInline.hotelName = res.data.data.ctripHotelName;
          this.formInline.star = res.data.data.starRating;
          this.formInline.pag =
            res.data.data.typeCore == 0
              ? "一般酒店"
              : res.data.data.typeCore == 1
                ? "核心酒店"
                : "";
          this.formInline.isInvoice =
            res.data.data.isInvoice == 0
              ? "否"
              : res.data.data.isInvoice == 1
                ? "是"
                : "";
          this.formInline.account = res.data.data.paymentAccount;
          this.formInline.hotelContacts = res.data.data.contacts;
          this.formInline.information = res.data.data.contactsTel;
          this.formInline.paymentName = res.data.data.paymentName;
          this.formInline.openingBankName = res.data.data.openingBankName;
          this.formInline.wchatGroupName = res.data.data.wchatGroupName;
          this.formInline.longitude = res.data.data.longitude;
          this.formInline.latitude = res.data.data.latitude;

          if (res.data.data.billType == 0) {
            this.formInline.emailType = "邮箱";
          } else if (res.data.data.billType == 1) {
            this.formInline.emailType = "产品经理";
          } else if (res.data.data.billType == 2) {
            this.formInline.emailType = "小程序";
          } else if (res.data.data.billType == 3) {
            this.formInline.emailType = "后台发单";
          }
          this.formInline.email = res.data.data.email;
          this.formInline.paymentMethod =
            res.data.data.payType == 0
              ? "一单一结"
              : res.data.data.payType == 1
                ? "预付款"
                : "";
          this.formInline.address = res.data.data.address;
          this.formInline.assistant = res.data.data.operateManagerId;
          this.formInline.hotelManagerIds = res.data.data.hotelManagerIds;

          this.formInline.id = res.data.data.id;
          this.formInline.paymentType =
            res.data.data.paymentType == 1
              ? "支付宝"
              : res.data.data.paymentType == 2
                ? "银行卡"
                : "";
          if (res.data.data.hotelManagerIds) {
            this.hotelManagerIds = res.data.data.hotelManagerIds.split(",");
          } else {
            this.hotelManagerIds = [];
          }

          console.log(this.formInline);
        })
        .catch((error) => { });
    },
    queryFn(val) {
      val = val.toUpperCase();
      this.hotelManagerList = this.oldHotelManagerList.filter(
        (item) =>
          item.telephone.indexOf(val) != "-1" ||
          item.nickName.toUpperCase().indexOf(val) != "-1"
      );
    },
  },
};
</script>

<style lang="less" scoped>
.el-form {
  display: flex;
  flex-wrap: wrap;
}

.el-form-item {
  width: 45%;
}


</style>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>