import req from "../utils/req"

// 获取基础参数设置列表 akai-api
export function getAdminConfig_apiak(params) {
    return req.get("/otaHotel/admin-config/getAdminConfig", params)
}

// 基础参数设置列表数据删除 akai-api
export function deleteAdminConfig_apiak(params){
    return req.get("/otaHotel/admin-config/deleteAdminConfig/{id}",params)
}

// 订单超时设置获取订单 akai-api
export function getOrderTimeoutConfig_apiak(params){
    return req.get("/otaHotel/order-timeout-config/getOrderTimeoutConfig",params)
}

// 传入当日或者次日的时间获取时间段 akai-api
export function getTimeLength_apiak(params){
    return req.get("/otaHotel/order-timeout-config/getTimeLength",params)
}

// 订单超时设置 删除 akai-api
export function deleteOrderTimeoutConfig_apiak(params){
    return req.get("/otaHotel/order-timeout-config/deleteOrderTimeoutConfig/{id}",params)
}

// 获取酒店模板设置接口 akai-api
export function getHotelTemplateSettingList_apiak(params){
    return req.get("/otaHotel/roomTemplate/getlist",params)
}
