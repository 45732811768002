<template>
  <div class="blackHotel">
    <!-- 黑名单酒店--查询表格 -->
    <div class="list">
      <el-table :data="tableData" style="width: 100%" max-height="500">
        <el-table-column
          prop="cityName"
          label="城市/酒店名称 备注-星级"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="address" align="center" label="酒店地址">
        </el-table-column>
        <el-table-column prop="hotelTel" align="center" label="酒店联系方式">
        </el-table-column>
        <!-- <el-table-column prop="" align="center" label="酒店负责人">
        </el-table-column>
        <el-table-column prop="" align="center" label="售卖状态">
        </el-table-column> -->
      </el-table>
      <!-- 分页 -->
      <el-pagination
        style="float: right"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="count"
        :page-sizes="[15, 30, 45, 60]"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getGroups_api } from "../../../apis/workbench";
import { getDevelop_api } from "../../../apis/hotel";
export default {
   props:['parentsData'],
  data() {
    return {
      personId: "", // 请求的person id
      groupId: "", // 请求的group id
      groupOptions: [], // 组别
      personOptions: [], // 人员
      jobOptions: [
        { label: "产品", value: 0 },
        { label: "运营", value: 1 },
        { label: "售前", value: 2 },
        { label: "售后", value: 3 },
        { label: "二次确认", value: 4 },
      ], // 职位选择
      currentPage: 1, //当前页
      count: 15, //当前页条数
      total: 0, //总数
      tableData: [], //酒店表格
      
        searchData: {
        isSearch: "1",
        page: "",
        rows: "",
         type: 2,
         blacklist:2,
        
      },
    };
  },
  created() {
    this.getBlackList();
  },
  methods: {
    //分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.count = val;
      this.getBlackList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getBlackList();
    },
    // 获取组别
    async getGroups(val) {
      let { code, data } = await getGroups_api({
        positionType: val,
      });
      if (code == 0) {
        this.formInline.group = "";
        this.personId = "";
        this.personOptions = [];
        this.groupOptions = data; // 后续可能更改格式
      }
    },
    // 获取组的信息(获取对应的人员列表)
    getPersons(val) {
      this.personId = ""; // 人员为空
      this.personOptions = this.groupOptions[val].list;
    },
    // 查询
    search() {
      this.groupId = this.groupOptions[this.formInline.group].keyy;
      console.log(this.formInline.job, "职位");
      console.log(this.groupId, "组别");
      console.log(this.personId, "人员id");
    },
    // 清空
    clear() {
      this.formInline = {};
      this.groupOptions = [];
      this.personOptions = [];
      this.personId = "";
    },
    //获取黑名单酒店
    async getBlackList(obj) {
       let searchDataa=JSON.parse(JSON.stringify(this.searchData))
      let searchDataf=Object.assign(searchDataa,this.parentsData)
       searchDataf.page = this.currentPage;
      searchDataf.rows = this.count;
      let { code, data } = await getDevelop_api(
        searchDataf
      //   {
      //   type: 2,
      //   page: this.currentPage,
      //   rows: this.count,
      //   blacklist: 2,
      //   isSearch: "1",
      //   cityName: obj ? obj.city : "",
      //   provinceName: obj ? obj.province : "",
      //   ctripHotelName: obj ? obj.name : "",
      //   start: obj ? obj.star : "",
      //   typeCore: obj ? obj.tag : "",
      // }
      );
      if (code == 0) {
        console.log(data, "12321321");
        this.tableData = data.records;
        this.total = data.total;
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input__inner {
  width: 170px !important;
}
.nav {
  background: #fff;
  padding: 15px 10px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  .btn {
    .el-button.active {
      background: #256def;
      color: #fff;
    }
  }
  .demo-form-inlines {
    height: 40px;
  }
}
.operation {
  color: #256ef1;
}
</style>