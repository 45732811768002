<template>
  <!-- 审核酒店驳回 -->
  <div class="auditReject">
    <el-dialog
      title="驳回申请"
      :visible.sync="dialogFormVisible"
      width="35%"
      top="10rem"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="驳回原因" :label-width="formLabelWidth">
          <el-input
            v-model="form.input"
            type="textarea"
            :rows="3"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" type="primary" @click="onSubmit"
              >保存</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getPass_api } from "../../../apis/hotel";
export default {
  props: {
    getAudit: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        input: "",
      },
      formLabelWidth: "80px",
      id: "",
    };
  },
  methods: {
    open(id) {
      this.dialogFormVisible = true;
      this.id = id;
    },
    //确认保存
    onSubmit() {
      this.reject();
    },
    close() {
      this.dialogFormVisible = false;
    },
    // 驳回审核逻辑
    async reject() {
      let { code, data } = await getPass_api({
        hotelSonId: this.id,
        isOk: 0,
        isOkMsg: this.form.input,
      });
      if (code == 0) {
        // console.log(data, "审核通过");
        this.dialogFormVisible = false;
        this.$message({
          message: "审核不通过",
          type: "warning",
        });
        this.getAudit();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-form {
  width: 70%;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: space-around;
}
/deep/.el-input__inner {
  background-color: #f1f1f1;
}
</style>