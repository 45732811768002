<template>
  <!-- 审核酒店 -->
  <div class="auditHotel">
    <div class="nav">
      <div class="btn">
        <el-button
          size="mini"
          type="primary"
          plain
          v-show="role_hotel_audit_pre"
          :class="btnCur == 0 ? 'active' : ''"
          @click="btnFn(0)"
          >待审核</el-button
        >
        <el-button
          size="mini"
          type="primary"
          plain
          v-show="role_hotel_audit_next"
          :class="btnCur == 1 ? 'active' : ''"
          @click="btnFn(1)"
          >已通过</el-button
        >
        <el-button
          size="mini"
          type="primary"
          plain
          v-show="role_hotel_audit_error"
          :class="btnCur == 2 ? 'active' : ''"
          @click="btnFn(2)"
          >未通过</el-button
        >
        <el-button
          v-if="role.includes('caiwu_jl') == true && btnCur == 0"
          size="mini"
          type="success"
         
          @click="openPass(1)"
          >批量通过</el-button
        >
        <el-button
          v-if="role.includes('market_jl') == true && btnCur == 0"
          size="mini"
          type="success"
         
          @click="openPass(2)"
          >批量通过</el-button
        >
        <el-button
          v-if="
            (role.includes('caiwu_jl') == true ||
              role.includes('market_jl') == true) &&
            btnCur == 0
          "
          size="mini"
          type="info"
         
          @click="openReject"
          >批量驳回</el-button
        >
      </div>
      <!-- 酒店查询 -->
      <el-form
        v-if="
          role.includes('all') ||
          role.includes('hr') ||
          role.includes('caiwu_jl') ||
          role.includes('market_jl') ||
          role.includes('operate_jl')||
           role.includes('market_baofang_zg')
        "
        :inline="true"
        label-width="auto"
        :model="formInline"
        class="demo-form-inlines"
      >
         <el-form-item label="职位">
            <el-select
              size="mini"
              style="width: 120px"
              v-model="formInline.roleName"
              @change="getGroups"
              placeholder="请选择"
            >
              <el-option
                v-for="item in jobOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="组别">
            <el-select
              size="mini"
              style="width: 120px"
              @change="getPersons"
              v-model="formInline.group"
              placeholder="请选择"
            >
              <el-option
                v-for="(item, index) in groupOptions"
                :key="item.keyy"
                :label="item.valuee"
                :value="index"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="人员">
            <el-select
              size="mini"
              style="width: 120px"
              v-model="formInline.people"
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in personOptions"
                :key="item.keyy"
                :label="item.valuee"
                :value="item.valuee"
              ></el-option>
            </el-select>
          </el-form-item>
        <el-form-item>
          <el-button size="mini" type="success" @click="query">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 酒店列表 -->

    <div class="list">
      <el-table
        :data="tableData"
        style="width: 100%"
        max-height="500"
        @selection-change="handleSelectionChange"
        >>
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          prop="ctripHotelName"
          label="城市/酒店名称"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="paymentAccount" label="收款账号" align="center">
        </el-table-column>
        <el-table-column prop="paymentName" label="收款户名" align="center">
        </el-table-column>
        <el-table-column prop="paymentType" label="支付类型" align="center" :formatter="formatPaymentType">
        </el-table-column>
        <el-table-column prop="openingBankName" label="开户行" align="center" >
        </el-table-column>
        <el-table-column prop="contacts" label="酒店联系人" align="center">
        </el-table-column>
        <el-table-column prop="contactsTel" label="酒店联系电话" width="120" align="center">
        </el-table-column>
        <el-table-column
          prop="typeCore"
          align="center"
          label="酒店标签"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.typeCore == "0" ? "一般酒店" : "核心酒店" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="payType"
          label="付款方式"
          align="center"
          :formatter="foematPayType"
        >
        </el-table-column>
        <el-table-column
          prop="billType"
          label="发单方式"
          align="center"
          :formatter="formatBillType"
        >
        </el-table-column>
        <el-table-column
          prop="isInvoice"
          label="是否需要开发票"
          align="center"
          :formatter="formatInvoice"
        >
        </el-table-column>
        <el-table-column
          prop="productManagerIdName"
          label="销售"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="released"
          label="发布状态"
          align="center"
          :formatter="formatReleased"
        ></el-table-column>
        <el-table-column
          prop="emsg"
          label="审核进度"
          align="center"
        ></el-table-column>
        <el-table-column
          v-if="btnCur == '2'"
          prop="examineMsgAll"
          align="center"
          label="驳回原因"
        ></el-table-column>

        <el-table-column
          v-if="
            (role.includes('caiwu_jl') == true ||
              role.includes('market_jl') == true) &&
            btnCur == '0'
          "
          label="操作"
          width="145"
          fixed="right"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              v-if="role.includes('caiwu_jl') == true"
              size="mini"
              @click="pass(scope.row.id)"
              type="primary"
              >通过</el-button
            >
            <el-button
              v-if="role.includes('market_jl') == true"
              size="mini"
              @click="passMark(scope.row.id)"
              type="primary"
              >通过</el-button
            >
            <el-button
              size="mini"
              @click="reject(scope.row.id)"
              type="primary"
              plain
              >驳回</el-button
            >
          </template>
        </el-table-column>

        <el-table-column
          v-if="
            (role.includes('all') == true ||
            role.includes('market_baofang') == true ||
             role.includes('market_shangwu') == true||
              role.includes('market_jl') == true) &&
            btnCur == '1'
          "
          label="操作"
          width="80"
          align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button size="mini" @click="release(scope.row)" type="primary"
              >发布</el-button
            >
          </template>
        </el-table-column>

        <el-table-column
          v-if="
            (role.includes('all') == true ||
             role.includes('market_jl') == true||
            role.includes('market_baofang') == true ||
              role.includes('market_shangwu') == true) &&
            btnCur == '2'
          "
          label="操作"
          width="100"
          align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="aginSubmit(scope.row)"
              type="primary"
              plain
              >重新提交</el-button
            >
          </template>
        </el-table-column>

        <!-- <el-table-column
          v-if="
            role.includes('market_baofang') == true ||
            role.includes('market_shangwu') == true
          "
          fixed="right"
          label="操作"
          width="330"
          align="center"
        >
          <template slot-scope="scope">
           
             <el-button
              v-if="btnCur == '1'"
              size="mini"
              @click="release(scope.row.id)"
              type="primary"
              >发布</el-button
            >

             <el-button
              v-if="role.includes('caiwu_jl') == true &&btnCur == '0'"
              size="mini"
              @click="pass(scope.row)"
              type="primary"
              >通过</el-button
            >
            <el-button
              v-if="role.includes('market_jl') == true &&btnCur == '0'"
              size="mini"
              @click="passMark(scope.row)"
              type="primary"
              >通过</el-button
            >
            <el-button
            v-if="btnCur == '0'"
              size="mini"
              @click="reject(scope.row)"
              type="primary"
              plain
              >驳回</el-button
            >
            <el-button
             v-if="btnCur == '2'"
              size="mini"
              @click="aginSubmit(scope.row)"
              type="primary"
              plain
              >重新提交</el-button
            >
           
          </template>
        </el-table-column> -->
      </el-table>
      <!-- 分页 -->
      <el-pagination
        style="float: right"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="count"
        :page-sizes="[15, 30, 45, 60]"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 待审核 -->

    <div class="list" v-if="btnCur == '3'">
      <el-table :data="tableData" style="width: 100%" max-height="500">
        <el-table-column
          prop="ctripHotelName"
          label="城市/酒店名称"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="starRating" align="center" label="酒店星级">
        </el-table-column>
        <el-table-column
          prop="typeCore"
          align="center"
          label="酒店标签"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.typeCore == "0" ? "一般酒店" : "核心酒店" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="examine" align="center" label="审核流程">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.examine == "0"
                  ? "审核中"
                  : scope.row.examine == "1"
                  ? "市场部经理审核通过"
                  : "审核中"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="billType" align="center" label="发单方式">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.billType == "0"
                  ? "邮箱"
                  : scope.row.billType == "1"
                  ? "产品经理"
                  : scope.row.billType =="2"
                  ? "小程序"
                  :"后台发单"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="
            role.includes('caiwu_jl') == true ||
            role.includes('market_jl') == true
          "
          fixed="right"
          label="操作"
          width="330"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              v-if="role.includes('caiwu_jl') == true"
              size="mini"
              @click="pass(scope.row.id)"
              type="primary"
              >通过</el-button
            >
            <el-button
              v-if="role.includes('market_jl') == true"
              size="mini"
              @click="passMark(scope.row.id)"
              type="primary"
              >通过</el-button
            >
            <el-button
              size="mini"
              @click="reject(scope.row.id)"
              type="primary"
              plain
              >驳回</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        style="float: right"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="count"
        :page-sizes="[15, 30, 45, 60]"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 已通过 -->
    <div class="list" v-if="btnCur == '4'">
      <el-table :data="tableData" style="width: 100%" max-height="500">
        <el-table-column
          prop="ctripHotelName"
          label="城市/酒店名称"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="starRating" align="center" label="酒店星级">
        </el-table-column>
        <el-table-column
          prop="typeCore"
          align="center"
          label="酒店标签"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.typeCore == "0" ? "一般酒店" : "核心酒店" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="billType" align="center" label="发单方式">
          <template slot-scope="scope">
            <div>
              {{
               scope.row.billType == "0"
                  ? "邮箱"
                  : scope.row.billType == "1"
                  ? "产品经理"
                  : scope.row.billType =="2"
                  ? "小程序"
                  :"后台发单"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="
            role.includes('caiwu_jl') == false &&
            role.includes('market_jl') == false
          "
          prop="examine"
          align="center"
          label="审核状态"
        >
          <template slot-scope="scope">
            <div>
              {{
                scope.row.examine == "0"
                  ? "待审核"
                  : scope.row.examine == "1"
                  ? "已通过"
                  : "未通过"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="role.includes('caiwu_jl') == true"
          prop="examineStateCw "
          align="center"
          label="审核状态"
        >
          <template slot-scope="scope">
            <div>
              {{
                scope.row.examineStateCw == "0"
                  ? "待审核"
                  : scope.row.examineStateCw == "1"
                  ? "已通过"
                  : "未通过"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="role.includes('market_jl') == true"
          prop="examineState"
          align="center"
          label="审核状态"
        >
          <template slot-scope="scope">
            <div>
              {{
                scope.row.examineState == "0"
                  ? "待审核"
                  : scope.row.examineState == "1"
                  ? "已通过"
                  : "未通过"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="
            role.includes('market_baofang') == true ||
            role.includes('market_shangwu') == true
          "
          fixed="right"
          label="操作"
          width="330"
          align="center"
        >
          <template slot-scope="scope">
            <div
              style="cursor: pointer"
              @click="release(scope.row)"
              class="operation"
            >
              发布
            </div>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        style="float: right"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="count"
        :page-sizes="[15, 30, 45, 60]"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 未通过 -->
    <div class="list" v-if="btnCur == '5'">
      <el-table :data="tableData" style="width: 100%" max-height="500">
        <el-table-column
          prop="ctripHotelName"
          label="城市/酒店名称"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="starRating" align="center" label="酒店星级">
        </el-table-column>
        <el-table-column
          prop="typeCore"
          align="center"
          label="酒店标签"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.typeCore == "0" ? "一般酒店" : "核心酒店" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="typeCore" align="center" label="发单方式">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.billType == "0"
                  ? "邮箱"
                  : scope.row.billType == "1"
                  ? "产品经理"
                  : scope.row.billType =="2"
                  ? "小程序"
                  :"后台发单"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="
            role.includes('caiwu_jl') == false &&
            role.includes('market_jl') == false
          "
          prop="examine"
          align="center"
          label="审核状态"
        >
          <template slot-scope="scope">
            <div>
              {{
                scope.row.examine == "0"
                  ? "待审核"
                  : scope.row.examine == "1"
                  ? "已通过"
                  : "未通过"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="role.includes('caiwu_jl') == true"
          prop="examineStateCw "
          align="center"
          label="审核状态"
        >
          <template slot-scope="scope">
            <div>
              {{
                scope.row.examineStateCw == "0"
                  ? "待审核"
                  : scope.row.examineStateCw == "1"
                  ? "已通过"
                  : "未通过"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="role.includes('market_jl') == true"
          prop="examineState"
          align="center"
          label="审核状态"
        >
          <template slot-scope="scope">
            <div>
              {{
                scope.row.examineState == "0"
                  ? "待审核"
                  : scope.row.examineState == "1"
                  ? "已通过"
                  : "未通过"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="examineMsgAll" align="center" label="驳回原因">
        </el-table-column>
        <el-table-column
          v-if="
            role.includes('market_baofang') == true ||
            role.includes('market_shangwu') == true
          "
          fixed="right"
          label="操作"
          width="330"
          align="center"
        >
          <template slot-scope="scope">
            <div @click="aginSubmit(scope.row)" class="operation">重新提交</div>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        style="float: right"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="count"
        :page-sizes="[15, 30, 45, 60]"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 通过审核弹出框 -->
    <AuditPass ref="auditPass" :getAudit="getAudit"></AuditPass>
    <!-- 驳回审核弹出框 -->
    <AuditReject :getAudit="getAudit" ref="auditReject"></AuditReject>
    <!-- 发布酒店弹出框 -->
    <Release ref="release"></Release>
    <ModifyDiagLog  ref="modify" />
  </div>
</template>

<script>
import { getGroups_api } from "../../../apis/workbench";
import {
  getAudit_api,
  getPass_api,
  getGroupList_api,
} from "../../../apis/hotel";
import AuditPass from "./AuditPass.vue";
import AuditReject from "./AuditReject.vue";
import Release from "./Release.vue";
import ModifyDiagLog from "./ModifyDiagLog.vue";
//引入local
import local from "@/utils/local.js";
import { getRoleKey } from "@/utils/rolekey";
export default {
  components: { AuditPass, AuditReject, Release,ModifyDiagLog },
   props:['parentsData'],
  data() {
    return {
      role_hotel_audit_pre: getRoleKey("role_hotel_audit_pre"),
      role_hotel_audit_next: getRoleKey("role_hotel_audit_next"),
      role_hotel_audit_error: getRoleKey("role_hotel_audit_error"),
      //酒店查询表单
      formInline: {
        roleName: "",
        group: "",
        people: "",
         goupId: "",
       
      },
      ajaxVestId: "", // 接口请求vest的id
      ajaxGroupId: "", // 接口请求group的id
      ajaxPersonId: "", // 接口请求person的id
      groupOptions: [], // 组别选择
      personOptions: [], // 人员选择
      jobOptions: [
        { label: "全部", value: "" },
        { label: "产品", value: 0 },
        { label: "运营", value: 1 },
      
      ], // 职位选择
      groupList: [],
      currentPage: 1, //当前页
      count: 15, //当前页条数
      total: 0, //总数
      tableData: [], //酒店表格
      //选项按钮
      btn: [{ name: "待审核" }, { name: "已通过" }, { name: "未通过" }],
      // 选项卡按钮
      btnCur: 0,
      //角色
      role: JSON.stringify(local.get("role")),
      hotelSonIds: [],
       searchData: {
        type: "1",
        isSearch: "1",
        page: "",
        rows: "",
        roleName: "",
        goupId: "",
        people: "",
      },
      multipleSelection:[]
    };
  },
  created() {
    this.getAudit();
  //  console.log(this.role, "哈啊哈哈");
  },
  methods: {
    //分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.count = val;
      this.currentPage=1
      this.getAudit();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getAudit();
    },
    // 选项卡点击事件
    btnFn(index) {
      this.btnCur = index;
      this.getAudit();
    },
      getChooseDataFn() {
      let getChooseData = {
        hotelSonIds: this.hotelSonIds,
        roleName: this.formInline.roleName,
        goupId: this.groupOptions[this.formInline.group]
          ? this.groupOptions[this.formInline.group].keyy
          : "",
        people: this.formInline.people,
         examineType: this.btnCur,
      };
      return getChooseData;
    },
     // 获取组别
    async getGroups(val) {
      let { code, data } = await getGroups_api({
        positionType: val,
      });
      if (code == 0) {
        this.formInline.group = "";
        this.formInline.people = "";
 this.formInline.goupId = "";
        this.personOptions = [];
        this.groupOptions = data; // 后续可能更改格式
      }
    },
    // 获取组的信息(获取对应的人员列表)
    getPersons(val) {
      this.formInline.people = ""; // 人员为空
      this.personOptions = this.groupOptions[val].list;
      this.ajaxGroupId = this.groupOptions[val].keyy; // 组id
       this.formInline.goupId = this.groupOptions[val].keyy; // 组id
      // console.log(this.ajaxGroupId, "组id");
    },
    // 查询
    search() {
      // if (this.groupOptions[this.formInline.group]) {
      //   this.groupId = this.groupOptions[this.formInline.group].keyy;
      //   this.getAudit(); // 调用查询函数
      // } else {
      //   this.groupId = "";
      //   this.getAudit(); // 调用查询函数
      // }
       this.currentPage=1
        this.getAudit(); 
    },
    // 清空
    clear() {
      this.formInline = {};
      this.groupOptions = [];
      this.personOptions = [];
    },
    //获取审核酒店列表
    async getAudit(obj) {
       let searchDataa=JSON.parse(JSON.stringify(this.searchData))
      let searchDataf=Object.assign(searchDataa,this.formInline,this.parentsData)
      searchDataf.page = this.currentPage;
      searchDataf.rows = this.count;
      searchDataf.examineType = this.btnCur;
      let { code, data } = await getAudit_api(
        searchDataf
      //   {
      //   page: this.currentPage,
      //   rows: this.count,
      //   examineType: this.btnCur,
      //   isSearch: "1",
      //   roleName: this.formInline.roleName,
      //   goupId: this.ajaxGroupId == undefined ? "" : this.ajaxGroupId,
      //   people: this.formInline.people,
      //   cityName: obj ? obj.city : "",
      //   provinceName: obj ? obj.province : "",
      //   ctripHotelName: obj ? obj.name : "",
      //   start: obj ? obj.star : "",
      //   typeCore: obj ? obj.tag : "",
      //   Ctrip: obj ? obj.vest : "",
      //   CtripStatus: obj ? obj.xiecheng : "",
      //   QunarStatus: obj ? obj.qunaer : "",
      //   MeiTuanStatus: obj ? obj.meituan : "",
      //   FilPigStatus: obj ? obj.feizhu : "",
      //   SameCityStatus: obj ? obj.yilong : "",
      // }
      );
      if (code == 0) {
       // console.log(data, "3356");
        this.tableData = data.records;
        this.total = data.total;
      }
    },
    //财务经理通过审核
    pass(id) {
      this.$refs.auditPass.open(id);
    //  this.getAudit();
    },
    //市场部经理通过审核
    passMark(id) {
      this.$confirm("是否确认通过审核?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.passMarks(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消审核",
          });
        });
    },
    async passMarks(id) {
      let { code, data } = await getPass_api({
        hotelSonId: id,
        isOk: 1,
        isOkMsg: "",
      });
      if (code == 0) {
     //   console.log(data, "审核通过");
        this.$message({
          type: "success",
          message: "已通过审核",
        });
        this.getAudit();
      }
    },
    //驳回审核
    reject(id) {
      this.$refs.auditReject.open(id);
    },
    //发布酒店
    release(row) {
       console.log(row, "发布了");
      this.$refs.release.open(row.id,row.ctripHotelName);
    },
    // 选择职位
    changeJob(data) {
      // console.log(data);
      this.formInline.roleName = data;
      this.formInline.group = "";
      this.getGroupList();
    },
    // 获取组别
    async getGroupList() {
      let { code, data } = await getGroupList_api({
        positionType: this.formInline.roleName == "产品" ? 0 : 1,
      });
      if (code == 0) {
        // console.log(data, "111111");
        this.groupList = data;
      }
    },
    query() {
      this.getAudit();
    },
    // 重新提交
    aginSubmit(row) {
     //  console.log(row);
      // this.$router.push({
      //   name: "Hotel",
      //   params: { 
      //     id: row.ctripHotelId,
      //     isSuper:1 },
      // });
       this.$refs.modify.open(row.id,1);
    },
    handleSelectionChange(val) {
      this.multipleSelection=val
    //  console.log(val);
      this.hotelSonIds = [];
      if (val) {
        val.forEach((item) => {
          this.hotelSonIds.push(item.id);
        });
      }
    },
    //批量通过
    openPass(val) {
    //  console.log(this.hotelSonIds)
      if (this.hotelSonIds.length == 0) {
        this.$alert("请勾选需要通过的酒店");
      } else {
        let id = this.hotelSonIds.join(",");
        if (val == 1) {
          this.pass(id);
        } else if (val == 2) {
          this.passMark(id);
        }
      }
    },
    openReject(){
      if (this.hotelSonIds.length == 0) {
        this.$alert("请勾选需要通过的酒店");
      } else{
         let id = this.hotelSonIds.join(",");
       this. reject(id) 
      }
    },
    foematPayType(row, column, cellValue) {
      if (cellValue == 0) {
        return "一单一结";
      } else if (cellValue == 1) {
        return "预付款";
      }
    },

    formatBillType(row, column, cellValue) {
      if (cellValue == 0) {
        return "邮件";
      } else if (cellValue == 1) {
        return "产品经理";
      } else if (cellValue == 2) {
        return "小程序";
      } else if (cellValue == 3) {
        return "后台发单";
      }
    },
    formatPaymentType(row, column, cellValue){
        if (cellValue == 1) {
        return "支付宝";
      } else if (cellValue == 2) {
        return "银行卡";
      }
    },
    formatReleased(row, column, cellValue){
        if (cellValue == 0) {
        return "未发布";
      } else if (cellValue == 1) {
        return "已发布";
      } else if (cellValue == 2) {
        return "已下线";
      }
    },
    formatInvoice(row, column, cellValue) {
      if (cellValue == 0) {
        return "否";
      } else if (cellValue == 1) {
        return "是";
      }
    },
    getMultipleSelection(){
      return this.multipleSelection
    }
  },
};
</script>

<style lang="less" scoped>
.nav {
  background: #fff;
  padding: 15px 10px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  .btn {
    .el-button.active {
      background: #256def;
      color: #fff;
    }
  }
  .demo-form-inlines {
    height: 40px;
  }
}
.operation {
  color: #256ef1;
  cursor: pointer;
}
</style>