<template>
  <div class="generalHotel">
    <div class="nav">
      <div class="btn">
        <el-button
          size="mini"
          v-show="role_hotel_gen_move"
          type="primary"
          plain
          @click="piliang"
          >批量移动</el-button
        >
        <el-button
          size="mini"
          v-show="role_hotel_gen_handover"
          type="primary"
          plain
          @click="jiaojie"
          >交接酒店</el-button
        >
        <el-button
          size="mini"
          type="primary"
          plain
          v-show="role_hotel_core_setsales"
          @click="settingHotelSaleStatusFn"
          >设置/恢复售卖状态</el-button
        >
      </div>
      <!-- 酒店查询 -->
      <el-form
        v-if="
          role.includes('all') ||
          role.includes('hr') ||
          role.includes('caiwu_jl') ||
          role.includes('market_jl') ||
          role.includes('operate_jl') ||
          role.includes('market_baofang_zg')
        "
        :inline="true"
        label-width="auto"
        :model="formInline"
        class="demo-form-inlines"
      >
        <el-form-item label="职位">
          <el-select
            size="mini"
            style="width: 120px"
            v-model="formInline.roleName"
            @change="getGroups"
            placeholder="请选择"
          >
            <el-option
              v-for="item in jobOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="组别">
          <el-select
            size="mini"
            style="width: 120px"
            @change="getPersons"
            v-model="formInline.group"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in groupOptions"
              :key="item.keyy"
              :label="item.valuee"
              :value="index"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="人员">
          <el-select
            size="mini"
            style="width: 120px"
            v-model="formInline.people"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in personOptions"
              :key="item.keyy"
              :label="item.valuee"
              :value="item.valuee"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button size="mini" type="success" @click="search">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 酒店列表 -->
    <div class="list">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" align="center"> </el-table-column>
        <el-table-column
          prop="cityName"
          label="城市/酒店名称 备注-星级"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="statusList" align="center" label="售卖状态">
          <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.statusList" :key="index">
              <div
                :class="
                  item.value == '1' ? 'changeColor' : 'changeColor active'
                "
              >
                {{ item.key }}:{{ item.value == "1" ? "在售" : "停售" }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="orderNum"
          align="center"
          label="订单量"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="nightNum" align="center" label="间夜量">
        </el-table-column>
        <el-table-column
          prop="preOverthrow"
          align="center"
          label="确认前推翻率"
        >
        </el-table-column>
        <el-table-column prop="achievement" align="center" label="业绩">
        </el-table-column>
        <el-table-column prop="productManager" align="center" label="产品经理">
        </el-table-column>
        <el-table-column prop="groupName" align="center" label="组别">
        </el-table-column>
        <el-table-column prop="onlineTime" align="center" label="上线时间">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="330" align="center">
          <template slot-scope="scope">
            <!-- <div class="operation" style="cursor: pointer">
              <span @click="fangxing(scope.row)">房型</span>&nbsp;&nbsp;
              <span @click="rizhi(scope.row)">日志</span>
            </div>
            <div class="operation" style="cursor: pointer">
              <span @click="offlineFn(scope.row)">删除</span>&nbsp;&nbsp;
              <span @click="change(scope.row)">修改</span>
            </div> -->
            <div class="tabBox">
              <div class="operation" @click="fangxing(scope.row)">房型</div>
              <div class="operation" @click="rizhi(scope.row)">日志</div>
              <div class="operation" @click="offlineFn(scope.row)">删除</div>
              <div @click="change(scope.row)" class="operation">修改</div>
              <div
                class="operation"
                v-if="role.includes('market_jl') == true"
                style="width: 140px"
                @click="openBaoliuDia(scope.row)"
              >
                保留天数
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        style="float: right"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="count"
        :page-sizes="[15, 30, 45, 60]"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <ModifyDiagLog ref="modify" />
    <Piliangyidong :getGenList="getGenList" ref="piliang" />
    <Jiaojie :getGenList="getGenList" ref="jiaojie" />
    <HotelSaleStatusDialog
      :getGenList="getGenList"
      ref="hotelSaleStatusDialog"
    ></HotelSaleStatusDialog>
    <BaoliuDia ref="baoliudia"></BaoliuDia>
  </div>
</template>

<script>
import { getGroups_api } from "../../../apis/workbench";
import { getCore_api, getGroupList_api } from "../../../apis/hotel";
import ModifyDiagLog from "./ModifyDiagLog.vue";
import Piliangyidong from "./piliangyidong.vue";
import Jiaojie from "./jiaojie.vue";
import HotelSaleStatusDialog from "./HotelSaleStatusDialog.vue";
import BaoliuDia from "./BaoliuDia.vue";

//引入local
import local from "@/utils/local.js";
import { getRoleKey } from "@/utils/rolekey";
export default {
  components: { ModifyDiagLog,BaoliuDia, Piliangyidong, Jiaojie, HotelSaleStatusDialog },
  props: {
    parentsData: {
      default: "",
    },
  },
  data() {
    return {
      role_hotel_gen_move: getRoleKey("role_hotel_gen_move"),
      role_hotel_gen_handover: getRoleKey("role_hotel_gen_handover"),
      role_hotel_core_setsales: getRoleKey("role_hotel_gen_setsales"),
      //酒店查询表单
      formInline: {
        roleName: "",
        group: "",
        goupId: "",
        people: "",
      },
      ajaxGroupId: "",
      // 职位
      jobOptions: [
        { label: "全部", value: "" },
        { label: "产品", value: 0 },
        { label: "运营", value: 1 },
        // { label: "售前", value: 2, disabled: true },
        // { label: "售后", value: 3, disabled: true },
        // { label: "二次确认", value: 4, disabled: true },
      ], // 职位选择
      groupList: [],
      currentPage: 1, //当前页
      count: 15, //当前页条数
      total: 0, //总数
      tableData: [], //酒店表格
      //角色
      role: JSON.stringify(local.get("role")),
      multipleSelection: [],
      hotelSonIds: [],
      personOptions: [],
      groupOptions: [],
      searchData: {
        type: "0",
        isSearch: "1",
        page: "",
        rows: "",
        roleName: "",
        goupId: "",
        people: "",
      },
    };
  },
  created() {
    this.getGenList();
  },
  methods: {
    getChooseDataFn() {
      let getChooseData = {
        hotelSonIds: this.hotelSonIds.join(","),
        roleName: this.formInline.roleName,
        goupId: this.groupOptions[this.formInline.group]
          ? this.groupOptions[this.formInline.group].keyy
          : "",
        people: this.formInline.people,
      };
      return getChooseData;
    },
    //分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.count = val;
      this.currentPage = 1;
      this.getGenList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getGenList();
    },
    //多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(this.multipleSelection);
      let hotelSonIds = [];
      if (val) {
        val.forEach((item) => {
          hotelSonIds.push(item.hotelId);
        });
        // hotelSonIds=hotelSonIds.join(',')
      } else {
        hotelSonIds = [];
      }
      this.hotelSonIds = hotelSonIds;
      this.$emit("getChoose", hotelSonIds);
    },
    // 查询
    search() {
      // if (this.groupOptions[this.formInline.group]) {
      //   this.groupId = this.groupOptions[this.formInline.group].keyy;
      //   this.getGenList(); // 调用查询函数
      // } else {
      //   this.groupId = "";
      //   this.getGenList(); // 调用查询函数
      // }

      this.currentPage = 1;
      this.getGenList(); // 调用查询函数
    },
    //获取一般酒店列表
    async getGenList(obj) {
      let searchDataa = JSON.parse(JSON.stringify(this.searchData));
      let searchDataf = Object.assign(
        searchDataa,
        this.formInline,
        this.parentsData
      );
      searchDataf.page = this.currentPage;
      searchDataf.rows = this.count;
      let { code, data } = await getCore_api(
        searchDataf
        //   {
        //   page: this.currentPage,
        //   rows: this.count,
        //   roleName: this.formInline.roleName,
        //   goupId: this.ajaxGroupId == undefined ? "" : this.ajaxGroupId,
        //   people: this.formInline.people,
        //   type: 0,
        //   isSearch: "1",
        //   cityName: obj ? obj.city : "",
        //   provinceName: obj ? obj.province : "",
        //   ctripHotelName: obj ? obj.name : "",
        //   start: obj ? obj.star : "",
        //   typeCore: obj ? obj.tag : "",
        //   Ctrip: obj ? obj.vest : "",
        //   CtripStatus: obj ? obj.xiecheng : "",
        //   QunarStatus: obj ? obj.qunaer : "",
        //   MeiTuanStatus: obj ? obj.meituan : "",
        //   FilPigStatus: obj ? obj.feizhu : "",
        //   SameCityStatus: obj ? obj.yilong : "",
        //   timeStart: obj ? obj.timeStart : "",
        //   timeEnd: obj ? obj.timeEnd : "",
        //   ctripHotelId: obj ? obj.ctripHotelId : "",
        // }
      );
      if (code == 0) {
        // console.log(data, "224458");
        this.tableData = data.records;
        this.total = data.total;
      }
    },
    // 获取组别
    async getGroups(val) {
      let { code, data } = await getGroups_api({
        positionType: val,
      });
      if (code == 0) {
        this.formInline.group = "";
        this.formInline.people = "";
        this.formInline.goupId = "";

        this.personOptions = [];
        this.groupOptions = data; // 后续可能更改格式
      }
    },
    // 获取组的信息(获取对应的人员列表)
    getPersons(val) {
      this.formInline.people = ""; // 人员为空
      this.personOptions = this.groupOptions[val].list;
      this.ajaxGroupId = this.groupOptions[val].keyy; // 组id
      this.formInline.goupId = this.groupOptions[val].keyy; // 组id
      // console.log(this.ajaxGroupId, "组id");
    },

    query() {
      this.getGenList();
    },
    // 设置/恢复酒店售卖状态
    settingHotelSaleStatusFn() {
      if (this.multipleSelection.length >= 1) {
        this.$refs.hotelSaleStatusDialog.open(this.hotelSonIds, 0);
      } else {
        this.$message({
          type: "info",
          message: "请您至少选择一家酒店哦!",
        });
      }
    },
    // 下线
    offlineFn(row) {
      this.$confirm(`是否要将${row.cityName}进行删除操作?`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.offline(row); // 调用下线酒店的功能函数
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    offline(row) {
      this.$axios({
        method: "get",
        url: "/otaHotel/hotelSon/setBatchoffline?hotelSonIds=" + row.hotelId,
        headers: {
          "Content-Type": "application/json",
        },
        data: {},
      })
        .then((res) => {
          this.$message({
            type: "success",
            message: res.data.data,
          });
          this.getGenList();
        })
        .catch((error) => {});
    },
    // 修改
    change(row) {
      this.$refs.modify.open(row.hotelId);
    },

    // 房型
    fangxing(row) {
      local.set("roomPageHotelId", row.hotelId);
      this.$router.push({
        path: "/room",
        // query: {
        //   hotelId: row.hotelId,
        // },
      });
    },
    // 日志
    rizhi(row) {
      this.$router.push({
        path: "/roomloglist",
        query: {
          id: row.hotelId,
          hotelName: row.cityName,
        },
      });
    },
    // 批量移动
    piliang() {
      if (this.multipleSelection.length >= 1) {
        this.$refs.piliang.open(this.multipleSelection);
      } else {
        this.$message({
          type: "info",
          message: "至少选择一个酒店",
        });
      }
    },
    // 交接酒店
    jiaojie() {
      if (this.multipleSelection.length >= 1) {
        this.$refs.jiaojie.open(this.hotelSonIds.join(","));
      } else {
        this.$message({
          type: "info",
          message: "至少选择一个酒店",
        });
      }
    },
     openBaoliuDia(row) {
      this.$refs.baoliudia.open(row.hotelId);
    },
    getMultipleSelection(){
      return this.multipleSelection
    }
  },
};
</script>

<style lang="less" scoped>
.changeColor {
  font-size: 12px;
  color: #33b93f;
  &.active {
    color: #ee4d52;
  }
}
.nav {
  background: #fff;
  padding: 15px 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  .demo-form-inlines {
    height: 40px;
  }
}
.operation {
  color: #256ef1;
}

.tabBox {
  width: 200px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 60px;
  div {
    width: 70px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #b3d8ff;
    background: #ecf5ff;
    margin: 0px 4px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
  }
}
</style>