<template>
  <div>
    <!-- 申请保留 -->
    <el-dialog
      title="申请保留"
      :visible.sync="dialogFormVisible"
      width="350px"
      :close-on-click-modal="false"
    >
      <div>
        <el-form>
          <el-form-item label="保留天数">
            <el-input size="small" v-model="formData.retentionDays"></el-input>
          </el-form-item>
        </el-form>
        <div class="diafooterbtn">
          <el-button
            @click="cleanFn"
            style="width: 84px; margin-right: 10%"
            size="small"
            type="primary"
            plain
            >取消</el-button
          >
          <el-button
            style="width: 84px"
            @click="handEditData"
            size="small"
            type="primary"
            >保存</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { setRetentionDays_api } from "../../../apis/hotel";
export default {
  data() {
    return {
      formData: {
        retentionDays: "",
        hotelSonId: "",
      },
      dialogFormVisible: false,
    };
  },
  methods: {
    open(hotelId) {
      this.formData.hotelSonId = hotelId;
      this.dialogFormVisible = true;
    },
    cleanFn() {
      this.dialogFormVisible = false;
    },
    handEditData() {
      if (this.formData.retentionDays) {
        this.handEditDataFn();
      } else {
        this.$message.error("请输入天数");
      }
    },
    async handEditDataFn() {
      let { code, data } = await setRetentionDays_api(this.formData);
      if (code == 0) {
        this.$message.success("操作成功");
        this.dialogFormVisible = false;
        if (this.$parent.getGenList()) {
          this.$parent.getGenList();
        }
        if (this.$parent.getCoreLyHotelListFn()) {
          this.$parent.getCoreLyHotelListFn();
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
</style>