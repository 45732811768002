import { render, staticRenderFns } from "./jiaojie.vue?vue&type=template&id=bc6546f4&scoped=true&"
import script from "./jiaojie.vue?vue&type=script&lang=js&"
export * from "./jiaojie.vue?vue&type=script&lang=js&"
import style0 from "./jiaojie.vue?vue&type=style&index=0&id=bc6546f4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc6546f4",
  null
  
)

export default component.exports