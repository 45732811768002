<template>
  <!-- 批量下线 -->
  <div class="offlineApply">
    <el-dialog
      title=""
      :visible.sync="dialogFormVisible"
      width="25%"
      top="10rem"
      :close-on-click-modal="false"
    >
      <div class="title">
        <div>!</div>
        <span>提示！</span>
      </div>
      <div class="msg">是否要将选中的酒店进行下线操作</div>
      <el-form :model="form">
        <el-form-item>
          <div class="btn">
            <el-button size="small" @click="close">否</el-button>
            <el-button size="small" type="primary" @click="onSubmit"
              >是</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { setBatchoffline_api } from "../../../apis/hotel";
export default {
  props: {
    getOffLine: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {},
      formLabelWidth: "80px",
      hotelId: "", //子酒店id
      arr: [],
      obj: {},
    };
  },
  methods: {
    open(row) {
      row.forEach((item) => {
        this.arr.push(item.hotelId);
      });
      this.obj = this.arr.join(",");
      this.dialogFormVisible = true;
    },
    //确认提交
    async onSubmit() {
      let { code, data } = await setBatchoffline_api({
        hotelSonIds: this.obj,
      });
      if (code == 0) {
        console.log(data);
        this.$message({
          message: data,
          type: "success",
        });
        this.dialogFormVisible = false;
        this.getOffLine();
      }
    },
    // 取消
    close() {
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  display: flex;
  align-items: center;
  div {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: rgb(247, 162, 0);
    color: #fff;
    text-align: center;
    line-height: 24px;
  }
  span {
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    padding-left: 20px;
    box-sizing: border-box;
  }
}
/deep/.el-dialog__body {
  padding: 0 20px !important;
}
.el-form {
  padding: 30px 0;
  box-sizing: border-box;
}
.btn {
  display: flex;
  justify-content: space-around;
}
.msg {
  width: 100%;
  text-align: center;
  padding-top: 30px;
  box-sizing: border-box;
}
</style>