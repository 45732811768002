<template>
  <div class="reviseOrder">
    <el-dialog
      title="设置/恢复售卖状态"
      :visible.sync="dialogFormVisible"
      width="700px"
      top="5rem"
      :close-on-click-modal="false"
    >
      <el-form :model="formline">
        <el-form-item label="统一处理" :label-width="formLabelWidth">
          <el-radio-group v-model="allStatus" @change="checkAll">
            <el-radio :label="-1">不变</el-radio>
            <el-radio :label="1">在售</el-radio>
            <el-radio :label="0">停售</el-radio>
            <el-radio :label="2">删除</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="携程售卖状态" :label-width="formLabelWidth">
          <el-radio-group
            v-model="formline.tripSaleStatus"
            @change="checkCtirpType"
          >
            <el-radio :label="-1">不变</el-radio>
            <el-radio :label="1">在售</el-radio>
            <el-radio :label="0">停售</el-radio>
            <el-radio :label="2">删除</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="艺龙售卖状态" :label-width="formLabelWidth">
          <el-radio-group
            v-model="formline.yiLongSaleStatus"
            @change="checkYilongType"
          >
            <el-radio :label="-1">不变</el-radio>
            <el-radio :label="1">在售</el-radio>
            <el-radio :label="0">停售</el-radio>
            <el-radio :label="2">删除</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="去哪儿售卖状态" :label-width="formLabelWidth">
          <el-radio-group
            v-model="formline.quNarSaleStatus"
            @change="checkQunaerType"
          >
            <el-radio :label="-1">不变</el-radio>
            <el-radio :label="1">在售</el-radio>
            <el-radio :label="0">停售</el-radio>
            <el-radio :label="2">删除</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="美团售卖状态" :label-width="formLabelWidth">
          <el-radio-group
            v-model="formline.meiTuanSaleStatus"
            @change="checkMeituanType"
          >
            <el-radio :label="-1">不变</el-radio>
            <el-radio :label="1">在售</el-radio>
            <el-radio :label="0">停售</el-radio>
            <el-radio :label="2">删除</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="飞猪售卖状态" :label-width="formLabelWidth">
          <el-radio-group
            v-model="formline.flyPigSaleStatus"
            @change="checkFlypigType"
          >
            <el-radio :label="-1">不变</el-radio>
            <el-radio :label="1">在售</el-radio>
            <el-radio :label="0">停售</el-radio>
            <el-radio :label="2">删除</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="百度售卖状态" :label-width="formLabelWidth">
          <el-radio-group
            v-model="formline.baiduSaleStatus"
            @change="checkFlybaiduType"
          >
            <el-radio :label="-1">不变</el-radio>
            <el-radio :label="1">在售</el-radio>
            <el-radio :label="0">停售</el-radio>
            <el-radio :label="2">删除</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="萝卜售卖状态" :label-width="formLabelWidth">
          <el-radio-group
            v-model="formline.luoboSaleStatus"
            @change="checkFlyluoboType"
          >
            <el-radio :label="-1">不变</el-radio>
            <el-radio :label="1">在售</el-radio>
            <el-radio :label="0">停售</el-radio>
            <el-radio :label="2">删除</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="天下房仓售卖状态" :label-width="formLabelWidth">
          <el-radio-group
            v-model="formline.fctxSaleStatus"
            @change="checkFlyfctxType"
          >
            <el-radio :label="-1">不变</el-radio>
            <el-radio :label="1">在售</el-radio>
            <el-radio :label="0">停售</el-radio>
            <el-radio :label="2">删除</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button size="small" type="primary" plain @click="close"
              >取消</el-button
            >
            <el-button size="small" type="primary" @click="onSubmit"
              >保存</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { hotelShelves_api } from "../../../apis/hotel";
export default {
  props: {
    getCoreLyHotelListFn: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      formline: {
        tripSaleStatus: "-1", // 携程
        yiLongSaleStatus: "-1", // 艺龙
        quNarSaleStatus: "-1", // 去哪儿
        meiTuanSaleStatus: "-1", // 美团
        flyPigSaleStatus: "-1", // 飞猪
        baiduSaleStatus:'-1',//百度
        luoboSaleStatus:'-1',//萝卜
        fctxSaleStatus:'-1',//天下房仓
        hotelSonIds: [],
      },
      formLabelWidth: "125px",
      dialogFormVisible: false,
      hotelType: "",
      allStatus: -1,
    };
  },
  methods: {
    // 设置携程售卖状态
    checkCtirpType(item) {
      //  console.log(item, "选择携程");
    },
    // 设置艺龙售卖状态
    checkYilongType(item) {
      //  console.log(item, "选择艺龙");
    },
    // 设置去哪儿售卖状态
    checkQunaerType(item) {
      //   console.log(item, "选择去哪儿");
    },
    // 设置美团售卖状态
    checkMeituanType(item) {
      // console.log(item, "选择美团");
    },
    // 设置飞猪售卖状态
    checkFlypigType(item) {
      // console.log(item, "选择飞猪");
    },
    checkFlybaiduType(item){
      // console.log(item, "选择百度");
    },
    checkFlyluoboType(item){
      // console.log(item, "选择萝卜");

    },
    checkFlyfctxType(item){
      // console.log(item, "选择天下房仓");

    },

    // 打开dialog弹窗的数据回显
    open(hotelSonIds, hotelType) {
      this.formline = {
        tripSaleStatus: -1, // 携程
        yiLongSaleStatus: -1, // 艺龙
        quNarSaleStatus: -1, // 去哪儿
        meiTuanSaleStatus: -1, // 美团
        flyPigSaleStatus: -1, // 飞猪
        baiduSaleStatus:-1,//百度
        luoboSaleStatus:-1,//萝卜
        fctxSaleStatus:-1,//天下房仓

      };
      console.log(hotelSonIds, "11111111");
      this.dialogFormVisible = true;
      this.formline.hotelSonIds = hotelSonIds;
      this.hotelType = hotelType;
      this.allStatus = -1;
    },
    checkAll(item) {
      this.formline.tripSaleStatus = item; // 携程
      this.formline.yiLongSaleStatus = item; // 艺龙
      this.formline.quNarSaleStatus = item; // 去哪儿
      this.formline.meiTuanSaleStatus = item; // 美团
      this.formline.flyPigSaleStatus = item; // 飞猪
      this.formline.baiduSaleStatus = item; // 百度
      this.formline.luoboSaleStatus = item; // 萝卜
      this.formline.fctxSaleStatus = item; // 天下房仓
     

    },
    //确认保存
    async onSubmit() {
      let { code, data } = await hotelShelves_api(this.formline);
      if (code == 0) {
        this.$message.success(data ? data : "提交成功");
        this.dialogFormVisible = false;
        if (this.hotelType == 0) {
          this.$parent.getGenList();
        } else {
          this.$parent.getCoreLyHotelListFn();
        }
      }
    },
    // 关闭弹窗
    close() {
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input__inner {
  width: 260px;
  height: 40px;
}

/deep/.el-dialog {
  border-radius: 10px;
}

.el-form {
  width: 70%;
  margin: auto;
}

.btn {
  display: flex;
  justify-content: space-around;
}

/deep/.el-input__inner {
  background-color: #f1f1f1;
}
</style>