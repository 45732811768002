/*携程匹配弹窗 */ 
<template>
  <div>
    
    <el-dialog
      title="刷新艺龙酒店数据"
      :visible.sync="dialogFormVisible"
      width="28%"
      top="10rem"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="酒店ID" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.id"
            autocomplete="off"
            placeholder="手动输入艺龙酒店ID"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button size="small" type="primary" plain @click="close"
              >取消</el-button
            >
            <el-button size="small" type="primary" @click="hold"
              >确定</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { hotelStaticInfo_api } from "../../../apis/hotel";
export default {
  props: {
    getMatchList: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        id: "",
      },
      formLabelWidth: "100px",
      list: [], //数据
    };
  },
  methods: {
    open() {
      this.dialogFormVisible = true;
     
    },
    // 匹配
    async hold() {
      this.dialogFormVisible = false;
      let { code, data } = await hotelStaticInfo_api({
        hotelId: this.form.id,
      });
      if (code == 0) {
        console.log(data);
        this.$message({
          message: "刷新数据成功",
          type: "success",
        });
        this.form.id=''
        this.dialogFormVisible = false;
      }
    },
    // 取消
    close() {
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.el-form {
  width: 70%;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: space-around;
  padding-top: 10px;
  box-sizing: border-box;
}
/deep/.el-dialog {
  border-radius: 20px;
}
</style>